@charset "UTF-8";
*,
*:before,
*:after {
  box-sizing: border-box; }

html {
  line-height: 1.5;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  font-size: 14px;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media screen and (min-width: 48em) {
    html,
    body {
      font-size: 16px; } }

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  cursor: default;
  text-rendering: optimizeLegibility;
  color: #111111; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio,
canvas,
iframe,
img,
progress,
svg,
video {
  vertical-align: middle; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }
  a:hover {
    cursor: pointer; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: 600; }

dfn {
  font-style: italic; }

mark {
  background: #FFFF00;
  color: #000000; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

::selection,
::-moz-selection {
  text-shadow: none !important; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

iframe {
  border: 0; }

figure {
  margin: 1em 40px; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #111111;
  margin: 1em 0;
  padding: 0;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  border-style: solid;
  color: inherit;
  font: inherit; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
  cursor: pointer; }

button,
html input[type='button'],
input[type='reset'],
input[type='submit'],
input[type='file'] {
  border-radius: 0; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input {
  line-height: normal; }

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto; }

input[type='search'] {
  box-sizing: border-box;
  -webkit-appearance: none; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -moz-appearance: none;
  -webkit-appearance: none; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0; }

legend {
  padding: 0;
  border: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

[tabindex='-1']:focus {
  outline: none !important; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.hidden {
  display: none !important;
  visibility: hidden !important; }

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto; }

.container-fluid {
  padding-right: 1.14286rem;
  padding-left: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .container-fluid {
      padding-right: 0;
      padding-left: 0; } }

.row {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -1.14286rem;
  margin-left: -1.14286rem; }
  @media screen and (min-width: 48em) {
    .row {
      margin-right: -1rem;
      margin-left: -1rem; } }
  .row::after, .row::before {
    display: block; }

.row.reverse {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  position: relative;
  flex: 0 0 auto;
  padding-right: 1.14286rem;
  padding-left: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .col-xs,
    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12,
    .col-xs-offset-0,
    .col-xs-offset-1,
    .col-xs-offset-2,
    .col-xs-offset-3,
    .col-xs-offset-4,
    .col-xs-offset-5,
    .col-xs-offset-6,
    .col-xs-offset-7,
    .col-xs-offset-8,
    .col-xs-offset-9,
    .col-xs-offset-10,
    .col-xs-offset-11,
    .col-xs-offset-12 {
      padding-right: 1rem;
      padding-left: 1rem; } }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%; }

.col-xs-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%; }

.col-xs-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%; }

.col-xs-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%; }

.col-xs-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-0 {
  margin-left: 0; }

.col-xs-offset-1 {
  margin-left: 8.33333333%; }

.col-xs-offset-2 {
  margin-left: 16.66666667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333333%; }

.col-xs-offset-5 {
  margin-left: 41.66666667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333333%; }

.col-xs-offset-8 {
  margin-left: 66.66666667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333333%; }

.col-xs-offset-11 {
  margin-left: 91.66666667%; }

@media only screen and (min-width: 48em) {
  .container {
    width: 43rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-0,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    position: relative;
    flex: 0 0 auto;
    padding-right: 1.14286rem;
    padding-left: 1.14286rem; } }
  @media only screen and (min-width: 48em) and (min-width: 48em) {
    .col-sm,
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12,
    .col-sm-offset-0,
    .col-sm-offset-1,
    .col-sm-offset-2,
    .col-sm-offset-3,
    .col-sm-offset-4,
    .col-sm-offset-5,
    .col-sm-offset-6,
    .col-sm-offset-7,
    .col-sm-offset-8,
    .col-sm-offset-9,
    .col-sm-offset-10,
    .col-sm-offset-11,
    .col-sm-offset-12 {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media only screen and (min-width: 48em) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-0 {
    margin-left: 0; }
  .col-sm-offset-1 {
    margin-left: 8.33333333%; }
  .col-sm-offset-2 {
    margin-left: 16.66666667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333333%; }
  .col-sm-offset-5 {
    margin-left: 41.66666667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333333%; }
  .col-sm-offset-8 {
    margin-left: 66.66666667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333333%; }
  .col-sm-offset-11 {
    margin-left: 91.66666667%; } }

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .container {
    width: unset; }
  .container-fluid,
  .container {
    margin-right: auto;
    margin-left: auto; }
  .container-fluid {
    padding-right: 16px;
    padding-left: 16px; } }

@media only screen and (min-width: 64em) {
  .container {
    width: 59rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-0,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    position: relative;
    flex: 0 0 auto;
    padding-right: 1.14286rem;
    padding-left: 1.14286rem; } }
  @media only screen and (min-width: 64em) and (min-width: 48em) {
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12,
    .col-md-offset-0,
    .col-md-offset-1,
    .col-md-offset-2,
    .col-md-offset-3,
    .col-md-offset-4,
    .col-md-offset-5,
    .col-md-offset-6,
    .col-md-offset-7,
    .col-md-offset-8,
    .col-md-offset-9,
    .col-md-offset-10,
    .col-md-offset-11,
    .col-md-offset-12 {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media only screen and (min-width: 64em) {
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-0 {
    margin-left: 0; }
  .col-md-offset-1 {
    margin-left: 8.33333333%; }
  .col-md-offset-2 {
    margin-left: 16.66666667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333333%; }
  .col-md-offset-5 {
    margin-left: 41.66666667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333333%; }
  .col-md-offset-8 {
    margin-left: 66.66666667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333333%; }
  .col-md-offset-11 {
    margin-left: 91.66666667%; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71.25rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-0,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    position: relative;
    flex: 0 0 auto;
    padding-right: 1.14286rem;
    padding-left: 1.14286rem; } }
  @media only screen and (min-width: 75em) and (min-width: 48em) {
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12,
    .col-lg-offset-0,
    .col-lg-offset-1,
    .col-lg-offset-2,
    .col-lg-offset-3,
    .col-lg-offset-4,
    .col-lg-offset-5,
    .col-lg-offset-6,
    .col-lg-offset-7,
    .col-lg-offset-8,
    .col-lg-offset-9,
    .col-lg-offset-10,
    .col-lg-offset-11,
    .col-lg-offset-12 {
      padding-right: 1rem;
      padding-left: 1rem; } }

@media only screen and (min-width: 75em) {
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-0 {
    margin-left: 0; }
  .col-lg-offset-1 {
    margin-left: 8.33333333%; }
  .col-lg-offset-2 {
    margin-left: 16.66666667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333333%; }
  .col-lg-offset-5 {
    margin-left: 41.66666667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333333%; }
  .col-lg-offset-8 {
    margin-left: 66.66666667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333333%; }
  .col-lg-offset-11 {
    margin-left: 91.66666667%; } }

.col-no-padding {
  padding-left: 0;
  padding-right: 0; }

.nyc-icon::before, .input--error::after, .input--warning::after, .input--success::after, .input--date::before, .favorite-icon, .notification::after, .notification-dismiss::after, .tooltip__message::after, .tooltip__dismiss::after, .navigation__link--drop::after, .navigation__drop::after, .new-header__btn--drop::after, .user-menu__image-wrap::after, .menu-select__list::after, .list--arrows li::before, .list--links li::before {
  display: inline-block;
  font-family: 'nyc-icon';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.navigation__item--active .navigation__drop::after, .user-menu--active .user-menu__image-wrap::after {
  content: '\EA04'; }

.navigation__link--drop::after, .navigation__drop::after, .new-header__btn--drop::after, .user-menu__image-wrap::after, .menu-select__list::after, .list--arrows li::before, .list--links li::before {
  content: '\EA05'; }

.input--date::before {
  content: '\EA0B'; }

.notification::after, .notification-dismiss::after, .tooltip__dismiss::after {
  content: '\EA0E'; }

.job-list__item:hover .favorite-icon:hover {
  content: '\EA14'; }

.notification-brand::after, .tooltip--brand .tooltip__message::after, .tooltip__message::after {
  content: '\EA4A'; }

.input--success::after, .notification-success::after, .tooltip--success .tooltip__message::after {
  content: '\EA4B'; }

.input--error::after, .notification-error::after, .tooltip--error .tooltip__message::after {
  content: '\EA4C'; }

.notification-info::after, .tooltip--info .tooltip__message::after {
  content: '\EA4D'; }

.input--warning::after, .notification-warning::after, .tooltip--warning .tooltip__message::after {
  content: '\EA4E'; }

.nyc-icon--lg {
  font-size: 1.3333333333333333em;
  line-height: .75em;
  vertical-align: -15%; }

.nyc-icon-2x {
  font-size: 2em; }

.nyc-icon-3x {
  font-size: 3em; }

.nyc-icon-4x {
  font-size: 4em; }

.nyc-icon-5x {
  font-size: 5em; }

.nyc-icon-fw {
  width: 1.2857142857142858em;
  text-align: center; }

.nyc-icon-arrow-down-filled::before {
  content: '\EA01'; }

.nyc-icon-arrow-down-outlined::before {
  content: '\EA02'; }

.nyc-icon-arrow-down::before {
  content: '\EA03'; }

.nyc-icon-arrow-left::before {
  content: '\EA04'; }

.nyc-icon-arrow-right::before {
  content: '\EA05'; }

.nyc-icon-arrow-up-filled::before {
  content: '\EA06'; }

.nyc-icon-arrow-up-outlined::before {
  content: '\EA07'; }

.nyc-icon-arrow-up::before {
  content: '\EA08'; }

.nyc-icon-avatar::before {
  content: '\EA09'; }

.nyc-icon-briefcase::before {
  content: '\EA0A'; }

.nyc-icon-calendar::before {
  content: '\EA0B'; }

.nyc-icon-chat::before {
  content: '\EA0C'; }

.nyc-icon-check::before {
  content: '\EA0D'; }

.nyc-icon-close-cross::before {
  content: '\EA0E'; }

.nyc-icon-cropper::before {
  content: '\EA0F'; }

.nyc-icon-delete::before {
  content: '\EA10'; }

.nyc-icon-edit::before {
  content: '\EA11'; }

.nyc-icon-education::before {
  content: '\EA12'; }

.nyc-icon-favorite-empty::before {
  content: '\EA13'; }

.nyc-icon-favorite-filled::before {
  content: '\EA14'; }

.nyc-icon-file::before {
  content: '\EA15'; }

.nyc-icon-flat-boxes::before {
  content: '\EA16'; }

.nyc-icon-flat-desk-chair::before {
  content: '\EA17'; }

.nyc-icon-flat-headset::before {
  content: '\EA18'; }

.nyc-icon-flat-laptop::before {
  content: '\EA19'; }

.nyc-icon-flat-planning::before {
  content: '\EA1A'; }

.nyc-icon-flat-street::before {
  content: '\EA1B'; }

.nyc-icon-home::before {
  content: '\EA1C'; }

.nyc-icon-location::before {
  content: '\EA1D'; }

.nyc-icon-maker-yc::before {
  content: '\EA1E'; }

.nyc-icon-matching-empty::before {
  content: '\EA1F'; }

.nyc-icon-menu-hamburger::before {
  content: '\EA20'; }

.nyc-icon-method-draw-image::before {
  content: '\EA21'; }

.nyc-icon-network-facebook-inverted::before {
  content: '\EA22'; }

.nyc-icon-network-facebook::before {
  content: '\EA23'; }

.nyc-icon-network-google-inverted::before {
  content: '\EA24'; }

.nyc-icon-network-google::before {
  content: '\EA25'; }

.nyc-icon-network-insta-inverted::before {
  content: '\EA26'; }

.nyc-icon-network-insta::before {
  content: '\EA27'; }

.nyc-icon-network-linkedin-inverted::before {
  content: '\EA28'; }

.nyc-icon-network-linkedin::before {
  content: '\EA29'; }

.nyc-icon-network-rss-inverted::before {
  content: '\EA2A'; }

.nyc-icon-network-rss::before {
  content: '\EA2B'; }

.nyc-icon-network-twitter-inverted::before {
  content: '\EA2C'; }

.nyc-icon-network-twitter::before {
  content: '\EA2D'; }

.nyc-icon-network-whatsapp-inverted::before {
  content: '\EA2E'; }

.nyc-icon-network-xing-inverted::before {
  content: '\EA2F'; }

.nyc-icon-network-xing::before {
  content: '\EA30'; }

.nyc-icon-network-youtube-inverted::before {
  content: '\EA31'; }

.nyc-icon-network-youtube::before {
  content: '\EA32'; }

.nyc-icon-notification::before {
  content: '\EA33'; }

.nyc-icon-operator-minus::before {
  content: '\EA34'; }

.nyc-icon-operator-plus::before {
  content: '\EA35'; }

.nyc-icon-pause::before {
  content: '\EA36'; }

.nyc-icon-phrase::before {
  content: '\EA37'; }

.nyc-icon-play::before {
  content: '\EA38'; }

.nyc-icon-round-info::before {
  content: '\EA39'; }

.nyc-icon-search::before {
  content: '\EA3A'; }

.nyc-icon-shape::before {
  content: '\EA3B'; }

.nyc-icon-share-facebook-colored::before {
  content: '\EA3C'; }

.nyc-icon-share-facebook-stripped::before {
  content: '\EA3D'; }

.nyc-icon-share-facebook::before {
  content: '\EA3E'; }

.nyc-icon-share-link::before {
  content: '\EA3F'; }

.nyc-icon-share-linkedin-colored::before {
  content: '\EA40'; }

.nyc-icon-share-linkedin::before {
  content: '\EA41'; }

.nyc-icon-share-mail-inverted::before {
  content: '\EA42'; }

.nyc-icon-share-mail::before {
  content: '\EA43'; }

.nyc-icon-share-print-inverted::before {
  content: '\EA44'; }

.nyc-icon-share-print::before {
  content: '\EA45'; }

.nyc-icon-share-twitter-colored::before {
  content: '\EA46'; }

.nyc-icon-share-twitter-stripped::before {
  content: '\EA47'; }

.nyc-icon-share-twitter::before {
  content: '\EA48'; }

.nyc-icon-time::before {
  content: '\EA49'; }

.nyc-icon-tip::before {
  content: '\EA4A'; }

.nyc-icon-toast-check::before {
  content: '\EA4B'; }

.nyc-icon-toast-error::before {
  content: '\EA4C'; }

.nyc-icon-toast-information::before {
  content: '\EA4D'; }

.nyc-icon-toast-warning::before {
  content: '\EA4E'; }

.nyc-icon-video::before {
  content: '\EA4F'; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
  letter-spacing: -.7px; }
  h1 + p,
  h1 + ul,
  h1 + ol,
  h1 + dl,
  h2 + p,
  h2 + ul,
  h2 + ol,
  h2 + dl,
  h3 + p,
  h3 + ul,
  h3 + ol,
  h3 + dl,
  h4 + p,
  h4 + ul,
  h4 + ol,
  h4 + dl,
  h5 + p,
  h5 + ul,
  h5 + ol,
  h5 + dl,
  h6 + p,
  h6 + ul,
  h6 + ol,
  h6 + dl {
    margin-top: 0.57143rem; }
    @media screen and (min-width: 48em) {
      h1 + p,
      h1 + ul,
      h1 + ol,
      h1 + dl,
      h2 + p,
      h2 + ul,
      h2 + ol,
      h2 + dl,
      h3 + p,
      h3 + ul,
      h3 + ol,
      h3 + dl,
      h4 + p,
      h4 + ul,
      h4 + ol,
      h4 + dl,
      h5 + p,
      h5 + ul,
      h5 + ol,
      h5 + dl,
      h6 + p,
      h6 + ul,
      h6 + ol,
      h6 + dl {
        margin-top: 0.5rem; } }

p,
ol,
ul,
dl {
  margin: 0;
  letter-spacing: -.1px; }
  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6,
  ol + h1,
  ol + h2,
  ol + h3,
  ol + h4,
  ol + h5,
  ol + h6,
  ul + h1,
  ul + h2,
  ul + h3,
  ul + h4,
  ul + h5,
  ul + h6,
  dl + h1,
  dl + h2,
  dl + h3,
  dl + h4,
  dl + h5,
  dl + h6 {
    margin-top: 1.14286rem; }
    @media screen and (min-width: 48em) {
      p + h1,
      p + h2,
      p + h3,
      p + h4,
      p + h5,
      p + h6,
      ol + h1,
      ol + h2,
      ol + h3,
      ol + h4,
      ol + h5,
      ol + h6,
      ul + h1,
      ul + h2,
      ul + h3,
      ul + h4,
      ul + h5,
      ul + h6,
      dl + h1,
      dl + h2,
      dl + h3,
      dl + h4,
      dl + h5,
      dl + h6 {
        margin-top: 1rem; } }
  p + p,
  ol + p,
  ul + p,
  dl + p, p +
  ol,
  ol +
  ol,
  ul +
  ol,
  dl +
  ol, p +
  ul,
  ol +
  ul,
  ul +
  ul,
  dl +
  ul, p +
  dl,
  ol +
  dl,
  ul +
  dl,
  dl +
  dl {
    margin-top: 0.57143rem; }
    @media screen and (min-width: 48em) {
      p + p,
      ol + p,
      ul + p,
      dl + p, p +
      ol,
      ol +
      ol,
      ul +
      ol,
      dl +
      ol, p +
      ul,
      ol +
      ul,
      ul +
      ul,
      dl +
      ul, p +
      dl,
      ol +
      dl,
      ul +
      dl,
      dl +
      dl {
        margin-top: 0.5rem; } }

em {
  font-style: italic; }

h1,
h2,
h3 {
  font-size: 1.42857rem;
  line-height: 1.2; }
  @media screen and (min-width: 64em) {
    h1,
    h2,
    h3 {
      font-size: 1.5rem; } }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

a {
  color: #111111; }
  a:hover {
    color: #565656; }
  a:focus {
    outline: none;
    opacity: 1;
    text-decoration: none; }

main p a {
  font-weight: bold; }

.figure-square {
  display: block;
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin: 0 auto; }
  @media screen and (min-width: 48em) {
    .figure-square {
      width: 140px;
      height: 140px; } }
  .figure-square__image {
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }

.card--primary .input__label, .card--secondary .input__label, .card--tertiary .input__label, .card--primary .input__switch-label, .card--secondary .input__switch-label, .card--tertiary .input__switch-label {
  color: #FFFFFF; }

.card {
  display: flex;
  margin-bottom: 1.14286rem;
  padding: 0;
  background-color: #FFFFFF;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  flex-direction: column; }
  @media screen and (min-width: 48em) {
    .card {
      margin-bottom: 2rem; } }
  .card > img {
    object-fit: cover; }
  .card__body {
    width: 100%;
    padding: 1.14286rem;
    align-self: center;
    hyphens: auto; }
    @media screen and (min-width: 48em) {
      .card__body {
        max-width: 52rem;
        padding: 2rem; } }
    @media screen and (min-width: 48em) {
      .card__body h3,
      .card__body .h3 {
        hyphens: none; } }
    .card__body:first-of-type {
      padding-top: 1.14286rem; }
      @media screen and (min-width: 48em) {
        .card__body:first-of-type {
          padding-top: 2rem; } }
    .card__body:last-of-type {
      padding-bottom: 1.14286rem; }
      @media screen and (min-width: 48em) {
        .card__body:last-of-type {
          padding-bottom: 2rem; } }
    .card__body + .card__body {
      border-top: 1px solid #E8E8E8; }
  .card--primary {
    background-color: #F77F00;
    color: #FFFFFF; }
  .card--secondary {
    background-color: #111111;
    color: #FFFFFF; }
  .card--tertiary {
    background-color: #E0E0E1;
    color: #FFFFFF; }
  .card--horizontal {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 48em) {
      .card--horizontal {
        flex-direction: row; }
        .card--horizontal > img {
          width: 50%;
          align-self: flex-start; } }
    .card--horizontal > .card__body {
      padding: 1.14286rem;
      order: 1;
      align-self: flex-start; }
      @media screen and (min-width: 48em) {
        .card--horizontal > .card__body {
          width: auto;
          padding: 2rem;
          order: initial; } }
      .card--horizontal > .card__body:first-of-type {
        padding-bottom: 0.57143rem; }
        @media screen and (min-width: 48em) {
          .card--horizontal > .card__body:first-of-type {
            padding: 2rem 1rem 2rem 2rem; } }
      .card--horizontal > .card__body:last-of-type {
        padding-top: 0.57143rem;
        border: 0; }
        @media screen and (min-width: 48em) {
          .card--horizontal > .card__body:last-of-type {
            padding: 2rem 2rem 2rem 1rem;
            flex-grow: 1; } }
    .card--horizontal + .card__body {
      border-top: 1px solid #E8E8E8; }
  .card--full-width {
    margin-right: -1.14286rem;
    margin-left: -1.14286rem; }
    @media screen and (min-width: 48em) {
      .card--full-width {
        margin-right: 0;
        margin-left: 0; } }
    .card--full-width .card__body {
      padding-bottom: 1.71429rem; }
      @media screen and (min-width: 48em) {
        .card--full-width .card__body {
          padding-bottom: 2rem; } }
  .card--full-height {
    height: 100%;
    margin-bottom: 0; }
    @media screen and (min-width: 48em) {
      .card--full-height {
        margin-bottom: 0; } }
    .card--full-height .card__body {
      height: 100%;
      flex: 1; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .card--full-height .card__body {
          flex: auto; } }
  .card--top {
    position: relative;
    z-index: 2;
    margin-top: -80px; }
    @media screen and (min-width: 48em) {
      .card--top {
        margin-top: -33px; } }
    @media screen and (min-width: 64em) {
      .card--top {
        margin-top: -51px; } }
  .card--avatar {
    margin-top: 50px; }
    @media screen and (min-width: 48em) {
      .card--avatar {
        margin-top: 70px; } }
    .card--avatar .card__body:first-of-type {
      padding-top: 0; }
    .card--avatar .figure-square {
      margin-top: -50px; }
      @media screen and (min-width: 48em) {
        .card--avatar .figure-square {
          margin-top: -70px; } }
  .card__button {
    margin-top: auto; }

h1 + .card,
h2 + .card,
h3 + .card,
.h1 + .card,
.h2 + .card,
.h3 + .card {
  margin-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    h1 + .card,
    h2 + .card,
    h3 + .card,
    .h1 + .card,
    .h2 + .card,
    .h3 + .card {
      margin-top: 1rem; } }

.table {
  width: 100%;
  border-color: #E0E0E1;
  color: inherit;
  line-height: inherit;
  text-align: left; }
  .table th,
  .table td {
    padding: 0.6875rem 0.25rem; }
  .table th {
    border-bottom: 1px solid #E0E0E1;
    font-weight: 600; }
  .table td {
    border-top: 1px solid #E0E0E1; }
  .table tbody tr:hover td {
    background-color: #E0E0E1; }

.title-bg__content, .top-badge, .job-list__top-badge, .tooltip__title {
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900;
  text-transform: uppercase; }

.title-bg__content, .top-badge, .job-list__top-badge {
  display: inline;
  color: #FFFFFF;
  line-height: 1.1;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone; }

.subtitle {
  font-size: 0.875rem; }
  @media screen and (min-width: 48em) {
    .subtitle {
      font-size: 1rem; } }

.title-bg {
  padding: 0.275rem 0 0.21875rem; }
  @media screen and (min-width: 48em) {
    .title-bg {
      padding: 0; } }
  .title-bg--box {
    margin: 0;
    font-size: 1.5rem; }
    @media screen and (min-width: 48em) {
      .title-bg--box {
        font-size: 2.675rem; } }
  .title-bg--sub {
    margin: 0.3125rem 0 0;
    font-size: 1.25rem; }
    @media screen and (min-width: 48em) {
      .title-bg--sub {
        margin: 0.625rem 0 0;
        font-size: 1.95rem; } }
  .title-bg--description {
    margin: 0;
    font-size: 1.0625rem; }
    .title-bg--description .title-bg__content {
      padding: 0.25rem 0.59375rem;
      font-weight: 600;
      line-height: 1.4;
      text-transform: none; }
  .title-bg__content {
    padding: 0.1875rem 0.59375rem 0.25rem;
    background-color: #111111;
    letter-spacing: 0;
    hyphens: auto; }
    @media screen and (min-width: 48em) {
      .title-bg__content {
        padding: 0 0.59375rem; } }

.btn.btn--outline.btn--primary:not(.btn--disabled):hover, .btn--primary {
  background-color: #F77F00;
  color: #FFFFFF; }

.btn.btn--outline.btn--secondary:not(.btn--disabled):hover, .btn--secondary {
  background-color: #000000;
  color: #FFFFFF; }

.btn.btn--outline.btn--tertiary:not(.btn--disabled):hover, .btn--tertiary {
  background-color: #B2B2B2;
  color: #FFFFFF; }

button:not(.btn):focus {
  outline: none; }

.btn {
  position: relative;
  display: inline-block;
  min-height: 3.14286rem;
  margin: 0;
  padding: 0.85714rem 1.14286rem 0.78571rem;
  border: 1px solid transparent;
  border-radius: 0;
  outline: none;
  white-space: nowrap;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 900;
  line-height: 1.35714rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: border 0.12s ease-in-out, background-color 0.12s ease-in-out, color 0.12s ease-in-out;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  text-overflow: ellipsis; }
  @media screen and (min-width: 48em) {
    .btn {
      min-height: 2.75rem;
      padding: 0.75rem 1rem 0.6875rem;
      line-height: 1.1875rem; } }
  .btn--icon {
    width: 3.14286rem;
    height: 3rem;
    padding: 0;
    background: transparent; }
    @media screen and (min-width: 48em) {
      .btn--icon {
        width: 2.75rem;
        height: 2.625rem; } }
    .btn--icon .nyc-icon::before {
      line-height: 3.07143rem; }
      @media screen and (min-width: 48em) {
        .btn--icon .nyc-icon::before {
          line-height: 2.6875rem; } }
  .btn:not(.btn--icon) {
    min-width: 100%;
    white-space: normal;
    word-break: break-word;
    hyphens: auto; }
    @media screen and (min-width: 48em) {
      .btn:not(.btn--icon) {
        min-width: 12.5rem; } }
    .btn:not(.btn--icon) + .btn:not(.btn--icon) {
      margin-top: 0.57143rem; }
      @media screen and (min-width: 48em) {
        .btn:not(.btn--icon) + .btn:not(.btn--icon) {
          margin-top: 0; } }
    .btn:not(.btn--icon) .nyc-icon {
      position: relative;
      top: 2px; }
  .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):hover.btn--primary {
    border: 1px solid #F77F00;
    background-color: #FFFFFF;
    color: #F77F00; }
  .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):hover.btn--secondary {
    border: 1px solid #000000;
    background-color: #FFFFFF;
    color: #000000; }
  .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):hover.btn--tertiary {
    border: 1px solid #E0E0E1;
    background-color: #FFFFFF;
    color: #B2B2B2; }
  .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active {
    box-shadow: none; }
    .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active.btn--primary {
      border-color: #ffb15e;
      background-color: #ffb15e; }
    .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active.btn--secondary {
      border-color: #666666;
      background-color: #666666; }
    .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active.btn--tertiary {
      border-color: #cccccc;
      background-color: #cccccc; }
    .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active:hover {
      color: #FFFFFF; }
    .btn:not(.btn--outline):not(.btn--disabled):not(.btn--loading):active:focus {
      outline: none; }
  .btn:focus {
    outline: none;
    color: #FFFFFF; }
  .btn.btn--outline:not(.btn--disabled):hover.btn--tertiary {
    border-color: #B2B2B2; }
  .btn.btn--outline:not(.btn--disabled):active.btn--primary {
    border-color: #ffb15e;
    background-color: #ffb15e; }
  .btn.btn--outline:not(.btn--disabled):active.btn--secondary {
    border-color: #666666;
    background-color: #666666; }
  .btn.btn--outline:not(.btn--disabled):active.btn--tertiary {
    border-color: #cccccc;
    background-color: #cccccc; }

.btn--outline {
  border: 1px solid #E8E8E8; }
  .btn--outline.btn--primary {
    border: 1px solid #F77F00;
    background-color: #FFFFFF;
    color: #F77F00; }
  .btn--outline.btn--secondary {
    border: 1px solid #000000;
    background-color: #FFFFFF;
    color: #000000; }
  .btn--outline.btn--tertiary {
    border: 1px solid #E0E0E1;
    background-color: #FFFFFF;
    color: #B2B2B2; }
    .btn--outline.btn--tertiary:focus {
      color: #B2B2B2; }

.btn--wide {
  display: block;
  width: 100%; }

p + .btn--wide,
ol + .btn--wide,
ul + .btn--wide,
dl + .btn--wide,
.btn--wide + .btn--wide {
  margin-top: 1.14286rem; }
  @media screen and (min-width: 48em) {
    p + .btn--wide,
    ol + .btn--wide,
    ul + .btn--wide,
    dl + .btn--wide,
    .btn--wide + .btn--wide {
      margin-top: 1rem; } }

.btn--dash {
  display: flex;
  justify-content: center;
  align-items: center; }
  .btn--dash hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #E0E0E1;
    margin: 1em 0;
    padding: 0;
    flex: 1; }
  .btn--dash .btn {
    min-width: 14.28571rem; }
    @media screen and (min-width: 48em) {
      .btn--dash .btn {
        min-width: 12.5rem; } }

.btn--loading {
  min-height: 2.75rem; }
  .btn--loading::before {
    content: unset; }
  .btn--loading::after {
    display: none; }
  .btn--loading .bounce {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 3px;
    border-radius: 100%;
    background-color: #FFFFFF;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .btn--loading .bounce1 {
    animation-delay: -.32s; }
  .btn--loading .bounce2 {
    animation-delay: -.16s; }

.btn--disabled, .btn--disabled[disabled] {
  opacity: .65;
  cursor: not-allowed; }
  .btn--disabled:not(.btn--outline):hover, .btn--disabled[disabled]:not(.btn--outline):hover {
    color: #FFFFFF; }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.nyc-icon::before, .input--error::after, .input--warning::after, .input--success::after, .input--date::before, .favorite-icon, .notification::after, .notification-dismiss::after, .tooltip__message::after, .tooltip__dismiss::after, .navigation__link--drop::after, .navigation__drop::after, .new-header__btn--drop::after, .user-menu__image-wrap::after, .menu-select__list::after, .list--arrows li::before, .list--links li::before {
  display: inline-block;
  font-family: 'nyc-icon';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.navigation__item--active .navigation__drop::after, .user-menu--active .user-menu__image-wrap::after {
  content: '\EA04'; }

.navigation__link--drop::after, .navigation__drop::after, .new-header__btn--drop::after, .user-menu__image-wrap::after, .menu-select__list::after, .list--arrows li::before, .list--links li::before {
  content: '\EA05'; }

.input--date::before {
  content: '\EA0B'; }

.notification::after, .notification-dismiss::after, .tooltip__dismiss::after {
  content: '\EA0E'; }

.job-list__item:hover .favorite-icon:hover {
  content: '\EA14'; }

.notification-brand::after, .tooltip--brand .tooltip__message::after, .tooltip__message::after {
  content: '\EA4A'; }

.input--success::after, .notification-success::after, .tooltip--success .tooltip__message::after {
  content: '\EA4B'; }

.input--error::after, .notification-error::after, .tooltip--error .tooltip__message::after {
  content: '\EA4C'; }

.notification-info::after, .tooltip--info .tooltip__message::after {
  content: '\EA4D'; }

.input--warning::after, .notification-warning::after, .tooltip--warning .tooltip__message::after {
  content: '\EA4E'; }

.input {
  position: relative;
  display: flex;
  padding-top: 0.57143rem;
  padding-bottom: 0.57143rem;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 0.91429rem;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between; }
  @media screen and (min-width: 48em) {
    .input {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      line-height: 0.875rem; } }
  .input--error, .input--warning, .input--success {
    position: relative; }
    .input--error .validation, .input--warning .validation, .input--success .validation {
      display: block;
      margin-top: 10px; }
      .input--error .validation:empty, .input--warning .validation:empty, .input--success .validation:empty {
        margin-top: 0; }
    .input--error::after, .input--warning::after, .input--success::after {
      position: absolute;
      top: 2.6875rem;
      right: 1rem;
      padding: 0.78571rem;
      font-size: 1.25rem;
      pointer-events: none; }
      @media screen and (min-width: 48em) {
        .input--error::after, .input--warning::after, .input--success::after {
          top: 0.6875rem;
          padding: 0.6875rem; } }
    .input--error.input--date.input--error::after, .input--error.input--date.input--warning::after, .input--error.input--date.input--success::after, .input--warning.input--date.input--error::after, .input--warning.input--date.input--warning::after, .input--warning.input--date.input--success::after, .input--success.input--date.input--error::after, .input--success.input--date.input--warning::after, .input--success.input--date.input--success::after {
      top: 2.64286rem;
      right: 3.21429rem; }
      @media screen and (min-width: 48em) {
        .input--error.input--date.input--error::after, .input--error.input--date.input--warning::after, .input--error.input--date.input--success::after, .input--warning.input--date.input--error::after, .input--warning.input--date.input--warning::after, .input--warning.input--date.input--success::after, .input--success.input--date.input--error::after, .input--success.input--date.input--warning::after, .input--success.input--date.input--success::after {
          top: 0.6875rem;
          right: 3.25rem; } }
  .input--error .validation {
    color: #FC3411; }
  .input--error::after {
    color: #FC3411; }
  .input--error .input__email,
  .input--error .input__number,
  .input--error .input__text,
  .input--error .input__textarea {
    border-color: #FC3411; }
    .input--error .input__email:hover, .input--error .input__email:focus, .input--error .input__email:active,
    .input--error .input__number:hover,
    .input--error .input__number:focus,
    .input--error .input__number:active,
    .input--error .input__text:hover,
    .input--error .input__text:focus,
    .input--error .input__text:active,
    .input--error .input__textarea:hover,
    .input--error .input__textarea:focus,
    .input--error .input__textarea:active {
      border-color: #FC3411; }
  .input--warning .validation {
    color: #F77F00; }
  .input--warning::after {
    color: #F77F00; }
  .input--warning .input__email,
  .input--warning .input__number,
  .input--warning .input__text,
  .input--warning .input__textarea {
    border-color: #F77F00; }
    .input--warning .input__email:hover, .input--warning .input__email:focus, .input--warning .input__email:active,
    .input--warning .input__number:hover,
    .input--warning .input__number:focus,
    .input--warning .input__number:active,
    .input--warning .input__text:hover,
    .input--warning .input__text:focus,
    .input--warning .input__text:active,
    .input--warning .input__textarea:hover,
    .input--warning .input__textarea:focus,
    .input--warning .input__textarea:active {
      border-color: #F77F00; }
  .input--success::after {
    color: #41D54A; }
  .input--select.input--error::after, .input--select.input--warning::after, .input--select.input--success::after {
    right: 2.1875rem; }
  .input--date {
    position: relative; }
    .input--date::before {
      position: absolute;
      top: 2.64286rem;
      right: 1.07143rem;
      z-index: 1;
      padding: 0.78571rem;
      color: #B2B2B2;
      font-size: 1.25rem;
      pointer-events: none; }
      @media screen and (min-width: 48em) {
        .input--date::before {
          top: 0.6875rem;
          right: 1.0625rem;
          padding: 0.6875rem; } }
  .input__email, .input__search, .input__number, .input__date, .input__text, .input__textarea, .input__select {
    background-color: #FFFFFF;
    font-size: 0.91429rem; }
    @media screen and (min-width: 48em) {
      .input__email, .input__search, .input__number, .input__date, .input__text, .input__textarea, .input__select {
        font-size: 0.875rem; } }
  .input__email, .input__search, .input__number, .input__date, .input__text, .input__textarea, .input__label, .input__select {
    display: block;
    border-radius: 0;
    -webkit-appearance: none; }
  .input__label {
    display: block;
    width: 100%;
    margin-bottom: 0.57143rem;
    color: #111111;
    font-weight: 700; }
    @media screen and (min-width: 48em) {
      .input__label {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem; } }
    .input__label abbr {
      padding-left: 0.57143rem;
      color: #F77F00;
      text-decoration: none; }
      @media screen and (min-width: 48em) {
        .input__label abbr {
          padding-left: 0.5rem; } }
  .input__email, .input__search, .input__number, .input__date, .input__text, .input__select {
    width: 100%;
    min-height: 3.14286rem;
    padding: 0.92857rem 1.14286rem;
    border: 1px solid #C3C3C3;
    outline: none;
    background-color: #FFFFFF;
    color: #111111;
    transition: border ease-in-out 0.12s;
    -webkit-appearance: none; }
    @media screen and (min-width: 48em) {
      .input__email, .input__search, .input__number, .input__date, .input__text, .input__select {
        overflow: hidden;
        min-height: 2.75rem;
        padding: 0.8125rem 1rem;
        white-space: nowrap;
        text-overflow: ellipsis; } }
    .input__email::-webkit-input-placeholder, .input__search::-webkit-input-placeholder, .input__number::-webkit-input-placeholder, .input__date::-webkit-input-placeholder, .input__text::-webkit-input-placeholder, .input__select::-webkit-input-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .input__email::-moz-placeholder, .input__search::-moz-placeholder, .input__number::-moz-placeholder, .input__date::-moz-placeholder, .input__text::-moz-placeholder, .input__select::-moz-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .input__email::-moz-placeholder, .input__search::-moz-placeholder, .input__number::-moz-placeholder, .input__date::-moz-placeholder, .input__text::-moz-placeholder, .input__select::-moz-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .input__email:-ms-input-placeholder, .input__search:-ms-input-placeholder, .input__number:-ms-input-placeholder, .input__date:-ms-input-placeholder, .input__text:-ms-input-placeholder, .input__select:-ms-input-placeholder {
      color: #717171;
      text-overflow: ellipsis !important; }
    .input__email:disabled, .input__search:disabled, .input__number:disabled, .input__date:disabled, .input__text:disabled, .input__select:disabled {
      background-color: #FAFAFA;
      cursor: not-allowed; }
      .input__email:disabled:hover, .input__email:disabled:focus, .input__email:disabled:active, .input__search:disabled:hover, .input__search:disabled:focus, .input__search:disabled:active, .input__number:disabled:hover, .input__number:disabled:focus, .input__number:disabled:active, .input__date:disabled:hover, .input__date:disabled:focus, .input__date:disabled:active, .input__text:disabled:hover, .input__text:disabled:focus, .input__text:disabled:active, .input__select:disabled:hover, .input__select:disabled:focus, .input__select:disabled:active {
        border-color: #C3C3C3; }
    .input__email:hover, .input__search:hover, .input__number:hover, .input__date:hover, .input__text:hover, .input__select:hover {
      border: 1px solid #B2B2B2; }
    .input__email:focus, .input__email:active, .input__search:focus, .input__search:active, .input__number:focus, .input__number:active, .input__date:focus, .input__date:active, .input__text:focus, .input__text:active, .input__select:focus, .input__select:active {
      border: 1px solid #111111;
      outline: none; }
    .input__email--disabled, .input__search--disabled, .input__number--disabled, .input__date--disabled, .input__text--disabled, .input__select--disabled {
      border: 0;
      background-color: transparent;
      pointer-events: none; }
  .input__textarea {
    width: 100%;
    min-height: 6.25rem;
    padding: 0.92857rem 1.14286rem;
    border: 1px solid #C3C3C3;
    outline: none;
    white-space: normal;
    background-color: #FFFFFF;
    color: #111111;
    line-height: 1.5;
    transition: border ease-in-out 0.12s; }
    @media screen and (min-width: 48em) {
      .input__textarea {
        padding: 0.8125rem 1rem; } }
    .input__textarea::-webkit-input-placeholder {
      color: #717171;
      opacity: 1; }
    .input__textarea::-moz-placeholder {
      color: #717171;
      opacity: 1; }
    .input__textarea::-moz-placeholder {
      color: #717171;
      opacity: 1; }
    .input__textarea:-ms-input-placeholder {
      color: #717171; }
    .input__textarea:disabled {
      background-color: #FAFAFA;
      cursor: not-allowed; }
      .input__textarea:disabled:hover, .input__textarea:disabled:focus, .input__textarea:disabled:active {
        border-color: #C3C3C3; }
    .input__textarea:hover {
      border: 1px solid #B2B2B2; }
    .input__textarea:focus, .input__textarea:active {
      border: 1px solid #111111;
      outline: none; }
  .input__switch {
    position: absolute;
    background-color: #FFFFFF;
    opacity: 0; }
    .input__switch-label {
      margin-right: 0.57143rem;
      font-weight: 400;
      line-height: 1.71429rem;
      cursor: pointer;
      vertical-align: super; }
      @media screen and (min-width: 48em) {
        .input__switch-label {
          margin-right: 0.5rem;
          line-height: 1.5rem; } }
    .input__switch-btn {
      position: relative;
      display: inline-block;
      width: 2.5rem;
      height: 1.25rem;
      cursor: pointer;
      flex: 0 1 auto; }
      .input__switch-btn + .input__switch-label {
        margin-right: 0;
        margin-left: 0.57143rem; }
        @media screen and (min-width: 48em) {
          .input__switch-btn + .input__switch-label {
            margin-left: 0.5rem; } }
      .input__switch-btn::after, .input__switch-btn::before {
        position: absolute;
        left: 0;
        display: block;
        content: ''; }
      .input__switch-btn::before {
        top: 20%;
        width: 2.5rem;
        height: 75%;
        border-radius: 0.625rem;
        background: #E0E0E1; }
      .input__switch-btn::after {
        top: -1px;
        left: 0;
        width: 1.5rem;
        height: 1.5rem;
        border: 0;
        border-radius: 100%;
        background: #FFFFFF;
        transition: left .3s;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12); }
      .input__switch-btn--square::before, .input__switch-btn--square::after {
        border-radius: 0; }
    .input__switch:checked + .input__switch-btn::before {
      background: #F77F00; }
    .input__switch:checked + .input__switch-btn::after {
      left: 1.0625rem; }
  .input--buttons {
    padding-bottom: 0.57143rem; }
    @media screen and (min-width: 48em) {
      .input--buttons {
        padding-bottom: 0.5rem; } }
    .input--buttons .input__label {
      position: relative;
      display: inline-block;
      width: auto;
      margin: 0;
      padding-left: 2.5rem;
      font-weight: 400;
      line-height: 1.625rem;
      cursor: pointer; }
      .input--buttons .input__label::before, .input--buttons .input__label::after {
        position: absolute;
        display: block;
        content: '';
        background-color: #FFFFFF; }
      .input--buttons .input__label::before {
        left: 0;
        width: 1.625rem;
        height: 1.625rem;
        border: 1px solid #C3C3C3;
        border-radius: 100%; }
      .input--buttons .input__label::after {
        top: 0.25rem;
        left: 0.25rem;
        width: 1.125rem;
        height: 1.125rem;
        border-radius: 100%;
        background-color: #F77F00;
        opacity: 0;
        transform: scale(0) translate3d(0, 0, 0);
        transition: opacity 0.12s ease, transform 0.12s ease; }
  .input__button {
    position: absolute;
    opacity: 0; }
    .input__button:checked + .input__label::before {
      border-color: #F77F00; }
    .input__button:checked + .input__label::after {
      opacity: 1;
      transform: scale(1) translate3d(0, 0, 0); }
    .input__button:focus + .input__label::before {
      border-color: #C46500; }
  .input--square .input__label::before, .input--square .input__label::after {
    border-radius: 0; }
  .input__datepicker-date {
    min-width: calc(100% / 2 - 10px);
    padding: 0.625rem 0 0.625rem 0.625rem;
    border: solid 1px #C3C3C3;
    background-color: #FFFFFF;
    color: #717171; }
    .input__datepicker-date:focus {
      outline: none; }
  .input__file {
    display: inline-block;
    margin-bottom: 1.25rem;
    padding: 0.625rem 1.5625rem 0.625rem 0;
    border-bottom: 1px solid #111111;
    color: #E0E0E1;
    cursor: pointer; }
    .input__file:hover {
      color: #565656; }
    .input__file:focus {
      outline: none; }
  .input__file-file {
    display: none; }
  .input__search {
    font-size: 0.875rem; }
    .input__search + .btn--icon {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #111111;
      font-size: 1.25rem; }
  .input--with-arrow .input__select {
    position: relative;
    z-index: 1;
    background-color: transparent;
    cursor: pointer;
    -moz-appearance: none; }
    .input--with-arrow .input__select::-ms-expand {
      display: none; }
    .input--with-arrow .input__select + .nyc-icon {
      position: absolute;
      right: 0;
      padding: 13px 16px;
      font-size: 11px; }
      .input--with-arrow .input__select + .nyc-icon::before {
        line-height: 20px;
        vertical-align: middle; }

.validation--checkbox .validation {
  position: relative;
  left: 1.875rem;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.validation--checkbox::after {
  top: 1px;
  right: auto;
  left: 0;
  padding: 0; }

.favorite-button {
  display: flex;
  width: 50px;
  border: 0;
  background-color: transparent;
  align-items: center; }

.favorite-icon {
  padding: 0;
  border: 0;
  transition: color 0.12s ease-in-out;
  cursor: pointer; }
  @media screen and (min-width: 64em) {
    .favorite-icon:hover {
      color: #565656; }
    .favorite-icon:focus {
      opacity: 1; } }
  .favorite-icon::before {
    font-size: 1.375rem; }
  @media screen and (min-width: 64em) {
    .favorite-icon__empty:hover::before {
      content: '\EA14'; } }
  .favorite-icon__empty::before {
    content: '\EA13'; }
  .favorite-icon__filled::before {
    content: '\EA14'; }

.notifications-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1011;
  width: 100%;
  padding: 0.3125rem;
  text-align: center;
  pointer-events: none; }
  @media screen and (min-width: 30em) {
    .notifications-wrapper {
      padding: 0; } }

.notification {
  position: relative;
  width: 100%;
  margin: 0 auto 1.875rem;
  padding: 0.625rem 3.125rem 0.625rem 3.75rem;
  border-radius: 2px;
  background: #FFFFFF;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8rem;
  text-align: left;
  transform: translateY(200%);
  transition: transform .4s, opacity .4s;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  pointer-events: all; }
  @media screen and (min-width: 30em) {
    .notification {
      display: flex;
      width: 470px;
      min-height: 65px;
      align-items: center; } }
  .notification::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 50px;
    height: 100%;
    content: '';
    background-color: #111111; }
  .notification::after {
    position: absolute;
    top: 50%;
    left: 15px;
    z-index: 3;
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    transform: translateY(-50%); }
  .notification-visible {
    opacity: 1;
    transform: translateY(0); }
  .notification-hidden {
    opacity: 0;
    transform: translateY(200%); }
  .notification-success::before {
    background-color: #41D54A; }
  .notification-error::before {
    background-color: #FC3411; }
  .notification-warning::before {
    background-color: #F77F00; }
  .notification-info::before {
    background-color: #E0E0E1; }
  .notification-brand::before {
    background-color: #F77F00; }
  .notification-message {
    z-index: 1;
    overflow: hidden;
    padding: 1em 0;
    color: #111111;
    font-size: 0.8rem; }
    @media screen and (min-width: 30em) {
      .notification-message {
        padding: 0; } }
  .notification-dismiss {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 50px;
    height: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 0;
    list-style-type: none;
    text-align: center; }
    .notification-dismiss::after {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #B2B2B2;
      font-size: 15px;
      transform: translate(-50%, -50%); }

.tooltip {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
  margin: 0 0 0.9375rem;
  border-radius: 2px;
  background: #FFFFFF;
  opacity: 1;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: .8rem;
  text-align: left;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer; }
  @media screen and (min-width: 48em) {
    .tooltip::before {
      position: absolute;
      top: 40px;
      right: 100%;
      width: 0;
      height: 0;
      margin-top: -5px;
      border: solid transparent;
      border-width: 5px;
      border-color: rgba(255, 225, 255, 0);
      border-right-color: #F77F00;
      content: ' ';
      pointer-events: none; } }
  .tooltip--success.tooltip::before {
    border-right-color: #41D54A; }
  .tooltip--success .tooltip__message::before {
    background-color: #41D54A; }
  .tooltip--error.tooltip::before {
    border-right-color: #FC3411; }
  .tooltip--error .tooltip__message::before {
    background-color: #FC3411; }
  .tooltip--warning.tooltip::before {
    border-right-color: #F77F00; }
  .tooltip--warning .tooltip__message::before {
    background-color: #F77F00; }
  .tooltip--info.tooltip::before {
    border-right-color: #E0E0E1; }
  .tooltip--info .tooltip__message::before {
    background-color: #E0E0E1; }
  .tooltip--brand.tooltip::before {
    border-right-color: #F77F00; }
  .tooltip--brand .tooltip__message::before {
    background-color: #F77F00; }
  .tooltip__message {
    margin: 0;
    padding: 1rem 2.5rem 1rem 3.75rem;
    color: #111111;
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.64;
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto; }
    .tooltip__message::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 50px;
      height: 100%;
      content: '';
      background-color: #F77F00; }
    .tooltip__message::after {
      position: absolute;
      top: 1.5rem;
      left: 15px;
      z-index: 3;
      color: #FFFFFF;
      font-size: 1.25rem;
      text-align: center;
      transform: translateY(-50%); }
  .tooltip__title {
    display: block;
    margin-bottom: 5px;
    font-size: 1.3rem;
    line-height: 1; }
  .tooltip__dismiss {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 40px;
    height: 100%;
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 0;
    list-style-type: none;
    text-align: center; }
    .tooltip__dismiss::after {
      position: absolute;
      top: 1.5rem;
      left: 50%;
      color: #111111;
      font-size: 0.9375rem;
      transform: translate(-50%, -50%); }
    .tooltip__dismiss:hover::after {
      color: #B2B2B2; }

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent; }

.tooltip-inner {
  max-width: 250px;
  padding: .75rem;
  border-radius: 0;
  background-color: #111111;
  color: #FFFFFF;
  text-align: left;
  text-align: start; }

.hover-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  white-space: normal;
  opacity: 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-transform: none;
  text-shadow: none;
  line-break: auto;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  filter: alpha(opacity=0); }
  .hover-tooltip.in {
    opacity: 1;
    filter: alpha(opacity=100); }
  .hover-tooltip.top {
    margin-top: -3px;
    padding: 5px 0; }
    .hover-tooltip.top .tooltip-arrow {
      bottom: -3px;
      left: 50%;
      margin-left: -12px;
      border-width: 12px 12px 0;
      border-top-color: #111111; }
    .hover-tooltip.top-left .tooltip-arrow {
      right: 2px;
      bottom: -20px;
      margin-bottom: 12px;
      border-width: 12px 12px 0;
      border-top-color: #111111; }
    .hover-tooltip.top-right .tooltip-arrow {
      bottom: -20px;
      left: 5px;
      margin-bottom: 12px;
      border-width: 12px 12px 0;
      border-top-color: #111111; }
  .hover-tooltip.right {
    margin-left: 3px;
    padding: 0 5px; }
    .hover-tooltip.right .tooltip-arrow {
      top: 50%;
      left: -3px;
      margin-top: -12px;
      border-width: 12px 12px 12px 0;
      border-right-color: #111111; }
  .hover-tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0; }
    .hover-tooltip.bottom .tooltip-arrow {
      top: -3px;
      left: 50%;
      margin-left: -12px;
      border-width: 0 12px 12px;
      border-bottom-color: #111111; }
    .hover-tooltip.bottom-left .tooltip-arrow {
      top: 3px;
      right: 2px;
      margin-top: -12px;
      border-width: 0 12px 12px;
      border-bottom-color: #111111; }
    .hover-tooltip.bottom-right .tooltip-arrow {
      top: 3px;
      left: 5px;
      margin-top: -12px;
      border-width: 0 12px 12px;
      border-bottom-color: #111111; }
  .hover-tooltip.left {
    margin-left: -3px;
    padding: 0 5px; }
    .hover-tooltip.left .tooltip-arrow {
      top: 50%;
      right: -3px;
      margin-top: -12px;
      border-width: 12px 0 12px 12px;
      border-left-color: #111111; }

@media screen and (min-width: 48em) {
  .columns {
    column-count: 2;
    column-gap: 40px; } }

@media screen and (min-width: 48em) {
  .columns--3 {
    column-count: 3;
    column-gap: 30px; } }

@media screen and (min-width: 48em) {
  .columns--4 {
    column-count: 4;
    column-gap: 20px; } }

form {
  margin-bottom: 0; }

.form__tag {
  display: inline-block;
  min-width: 72px;
  margin: 0 0.625rem 0.625rem 0;
  padding: 0.8125rem 1.125rem 0.75rem;
  background-color: #FAFAFA;
  color: #111111;
  text-align: left;
  text-decoration: none; }
  .form__tag-boolean {
    text-align: center; }
  .form__tag:last-of-type {
    margin-right: 0; }

.filter {
  margin-bottom: 1rem; }
  @media screen and (min-width: 48em) {
    .filter {
      margin-bottom: 2rem; } }
  .filter .card {
    margin-bottom: 0; }
    .filter .card__body:first-of-type + .card__body {
      padding-top: 0;
      border-top: 0; }
    @media screen and (min-width: 48em) {
      .filter .card__body:only-child {
        padding-bottom: 2rem; } }
    .filter .card .filter__heading {
      padding-top: 1.71429rem;
      padding-bottom: 1.71429rem; }
      @media screen and (min-width: 48em) {
        .filter .card .filter__heading {
          padding-top: 2rem;
          padding-bottom: 1.5rem; } }
    .filter .card .filter__search:first-of-type {
      padding-top: 0;
      padding-bottom: 1.14286rem; }
      @media screen and (min-width: 48em) {
        .filter .card .filter__search:first-of-type {
          padding-bottom: 1.5rem; } }
  .filter__heading .nyc-icon:only-child {
    top: auto;
    padding-left: 0.3125rem; }
    .filter__heading .nyc-icon:only-child::before {
      font-size: 0.5625rem; }
  .filter__fieldsets .card {
    margin-bottom: 0; }
    .filter__fieldsets .card .card__body {
      padding-top: 1.5rem; }
  .filter__sort .input {
    padding-top: 0.85714rem;
    padding-bottom: 0; }
    @media screen and (min-width: 48em) {
      .filter__sort .input {
        padding-top: 1rem;
        padding-bottom: 0; } }
  .filter__slider fieldset {
    padding-bottom: 1rem; }
    @media screen and (min-width: 48em) {
      .filter__slider fieldset {
        padding-bottom: 0; } }
  .filter__button {
    z-index: 1; }
  .filter legend {
    width: 100%;
    font-size: 1.14286rem;
    font-weight: 700; }
    @media screen and (min-width: 48em) {
      .filter legend {
        font-size: 1rem; } }
    .filter legend .nyc-icon {
      float: right;
      font-size: 1rem; }
      @media screen and (min-width: 64em) {
        .filter legend .nyc-icon {
          visibility: hidden; } }
      .filter legend .nyc-icon::before {
        line-height: 1.5rem; }
  .filter .input--buttons {
    flex-wrap: nowrap; }
    .filter .input--buttons > div {
      width: calc(100% - 1.625rem); }
    .filter .input--buttons:first-of-type {
      padding-top: 0.85714rem; }
      @media screen and (min-width: 48em) {
        .filter .input--buttons:first-of-type {
          padding-top: 1rem; } }
    .filter .input--buttons:last-of-type {
      padding-bottom: 0; }
      .filter .input--buttons:last-of-type + span a,
      .filter .input--buttons:last-of-type + a {
        display: block;
        margin-top: 0.57143rem; }
        @media screen and (min-width: 48em) {
          .filter .input--buttons:last-of-type + span a,
          .filter .input--buttons:last-of-type + a {
            margin-top: 0.625rem; } }
    .filter .input--buttons data {
      font-weight: 700;
      line-height: 1.625rem; }
  .filter .input__label {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 1.625rem;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .filter a {
    font-weight: 700; }
  .filter button + a {
    display: block;
    margin-top: 0.57143rem;
    font-size: 1.14286rem; }
    @media screen and (min-width: 48em) {
      .filter button + a {
        margin-top: 1rem;
        font-size: 1rem; } }

.typeahead {
  position: relative;
  width: 100%; }
  .typeahead__menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    overflow-y: auto;
    width: 100%;
    max-height: 250px;
    padding: 0.5rem 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0 0 3px 3px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }
    .typeahead__menu:empty {
      display: none; }
  .typeahead__menu-item {
    padding: 0.1875rem 1.25rem 0.1875rem 0.8125rem;
    line-height: 2; }
    .typeahead__menu-item--highlighted, .typeahead__menu-item:hover {
      background-color: #F0F0F0; }
    .typeahead__menu-item--selected {
      background-color: #F77F00;
      color: #FFFFFF; }

.navigation__container {
  position: relative;
  transition: visibility .25s, opacity .25s; }

.navigation__link--drop::after, .navigation__drop::after, .new-header__btn--drop::after, .user-menu__image-wrap::after, .menu-select__list::after {
  margin-left: 0.4375rem;
  font-size: 0.4375rem;
  transform: rotate(90deg);
  transform-origin: center; }

.navigation {
  background-color: #292929;
  color: #FFFFFF; }
  .navigation--sub {
    position: static;
    display: flex;
    width: 100%;
    margin: 0 auto;
    padding: 19px 0;
    flex-direction: column; }
    @media screen and (min-width: 64em) {
      .navigation--sub {
        width: 950px;
        padding: 45px 0 15px;
        flex-direction: row;
        justify-content: flex-start; } }
    @media screen and (min-width: 75em) {
      .navigation--sub {
        width: 1150px; } }
    .navigation--sub a:hover {
      color: #F77F00; }
    .navigation--sub .navigation__link {
      padding: 0;
      color: #FFFFFF; }
    .navigation--sub .navigation__drop::after {
      display: inline-block; }
      @media screen and (min-width: 64em) {
        .navigation--sub .navigation__drop::after {
          display: none !important; } }
    .navigation--sub .navigation--sub {
      position: static;
      display: none;
      width: calc(100% + 40px);
      margin-top: 15px;
      margin-left: -20px;
      padding: 15px 0 10px 38px;
      background-color: #373737;
      flex-direction: column; }
      @media screen and (min-width: 64em) {
        .navigation--sub .navigation--sub {
          display: block;
          width: 100%;
          margin: 0;
          padding-top: 18px;
          padding-left: 0;
          background-color: transparent; } }
  .navigation--user-menu.navigation--main .navigation__container > .navigation--sub {
    padding-bottom: 35px; }
    .navigation--user-menu.navigation--main .navigation__container > .navigation--sub > .navigation__item {
      padding-bottom: 15px;
      font-size: 17px;
      font-weight: 900;
      text-transform: uppercase; }
  .navigation--user-menu .navigation--sub .navigation--sub {
    display: block;
    margin-top: 0;
    padding-top: 20px;
    padding-left: 21px;
    background-color: #292929; }
    @media screen and (min-width: 64em) {
      .navigation--user-menu .navigation--sub .navigation--sub {
        padding-left: 0; } }
  .navigation__container {
    visibility: visible;
    width: 100%;
    background-color: #292929;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    flex-direction: column; }
    .navigation__item--active .navigation__container, .user-menu--active .navigation__container {
      cursor: default; }
    @media screen and (min-width: 64em) {
      .navigation__container {
        position: absolute;
        top: 100%;
        left: 0;
        visibility: hidden;
        padding: 0;
        opacity: 0;
        flex-direction: row;
        flex-wrap: wrap; } }
  .navigation__link {
    display: block;
    padding: 0;
    color: #111111;
    text-decoration: none; }
    @media screen and (min-width: 75em) {
      .navigation__link {
        padding: 28px 0; } }
    .navigation__link:focus,
    .navigation__link:focus a, .navigation__link:hover,
    .navigation__link:hover a {
      text-decoration: none; }
    @media screen and (min-width: 64em) {
      .navigation__link--drop::after {
        display: inline-block; } }
    .user-menu .navigation--sub > .navigation__item .navigation__link--drop::after {
      display: none; }
  .navigation__drop {
    position: relative; }
    .navigation__drop::after {
      position: inherit;
      top: -1px;
      display: none; }
      @media screen and (min-width: 64em) {
        .navigation__drop::after {
          display: inline-block; } }
    .user-menu .navigation--sub > .navigation__item .navigation__drop::after {
      display: none; }
  .navigation__item {
    display: flex;
    line-height: 1rem;
    text-align: left; }
    @media screen and (min-width: 64em) {
      .navigation__item:hover > .navigation--sub {
        display: flex; } }
    .navigation__item--top,
    .navigation__item--top a {
      transition: all .25s; }
      .navigation__item--top:hover,
      .navigation__item--top a:hover {
        color: #F77F00; }
    @media screen and (min-width: 64em) {
      .navigation__item--active > .navigation--sub,
      .navigation__item--active > .navigation__container {
        display: flex;
        visibility: visible;
        opacity: 1; } }
    .navigation__item .menu-select__list--dropless::after {
      display: none; }
    .navigation--main > .navigation__item--lang-select-mobile {
      position: relative;
      z-index: 1;
      display: block;
      width: calc(100% + 20px);
      margin: 15px -10px 26px; }
      @media screen and (min-width: 64em) {
        .navigation--main > .navigation__item--lang-select-mobile {
          display: none; } }
      .navigation--main > .navigation__item--lang-select-mobile .menu-select__list {
        overflow: visible;
        height: auto;
        border: 0;
        background-color: #373737; }
        .navigation--main > .navigation__item--lang-select-mobile .menu-select__list::after {
          right: 11px;
          transition: transform .25s, top .25s; }
        .navigation--main > .navigation__item--lang-select-mobile .menu-select__list--visible .menu-select__item {
          min-height: 42px; }
        .navigation--main > .navigation__item--lang-select-mobile .menu-select__list--visible::after {
          top: 18px;
          right: 11;
          transform: rotate(-90deg); }
      .navigation--main > .navigation__item--lang-select-mobile .menu-select__item {
        overflow: hidden;
        height: 0;
        min-height: 0;
        border: 0;
        background: #373737;
        text-align: center;
        transition: min-height .25s; }
        .navigation--main > .navigation__item--lang-select-mobile .menu-select__item:first-child {
          min-height: 42px; }
      .navigation--main > .navigation__item--lang-select-mobile .menu-select__link {
        padding-right: 2em;
        background-color: transparent;
        text-decoration: none; }
    .navigation__item .navigation--sub > .navigation__item {
      padding: 0 0 28px;
      font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.2px;
      text-transform: none;
      flex-direction: column; }
      @media screen and (min-width: 64em) {
        .navigation__item .navigation--sub > .navigation__item {
          padding: 0 20px 20px;
          font-size: 18px;
          font-weight: 900;
          letter-spacing: .2px;
          text-transform: uppercase; }
          .navigation__item .navigation--sub > .navigation__item:first-child {
            padding-left: 0; }
          .navigation__item .navigation--sub > .navigation__item:last-child {
            padding-right: 0; } }
      .navigation__item .navigation--sub > .navigation__item:last-child {
        padding-bottom: 0; }
        @media screen and (min-width: 64em) {
          .navigation__item .navigation--sub > .navigation__item:last-child {
            padding-bottom: 20px; } }
    .navigation__item .navigation--sub .navigation--sub .navigation__item {
      padding-bottom: 29px;
      font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.2px;
      line-height: 1.1;
      text-transform: none; }
      @media screen and (min-width: 64em) {
        .navigation__item .navigation--sub .navigation--sub .navigation__item {
          padding-right: 0;
          padding-bottom: 17px;
          padding-left: 0;
          letter-spacing: 1px; } }
      .navigation__item .navigation--sub .navigation--sub .navigation__item:last-child {
        padding-bottom: 5px; }
  .navigation--main {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 44px);
    margin-bottom: 0;
    padding: 23px 25px 80px;
    -webkit-overflow-scrolling: touch; }
    @media screen and (min-width: 64em) {
      .navigation--main {
        position: static;
        display: flex;
        overflow: hidden;
        height: auto;
        padding: 0;
        padding-right: 5px;
        background-color: #FFFFFF;
        color: #000000;
        align-items: stretch;
        flex-flow: row nowrap;
        justify-content: flex-start; } }
    .navigation--main > .navigation__item {
      display: flex;
      margin: 15px 0 20px;
      font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 17px;
      font-weight: 900;
      text-transform: uppercase;
      align-items: center;
      flex-flow: row wrap; }
      @media screen and (min-width: 64em) {
        .navigation--main > .navigation__item {
          margin: 0;
          padding: 20px 15px;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: .3px;
          text-transform: none;
          transition: all .25s;
          align-content: center; }
          .navigation--main > .navigation__item:hover {
            color: #F77F00;
            transition: all .25s;
            cursor: pointer; }
          .navigation--main > .navigation__item--active {
            border-bottom-color: #F77F00;
            color: #000000; }
            .navigation--main > .navigation__item--active .navigation__drop {
              pointer-events: none; }
          .navigation--main > .navigation__item:last-child {
            padding-bottom: 20px; } }
      .navigation--main > .navigation__item:last-child {
        margin-bottom: 0; }
      .navigation--main > .navigation__item > .navigation__link--drop::after {
        display: none; }
        @media screen and (min-width: 64em) {
          .navigation--main > .navigation__item > .navigation__link--drop::after {
            display: inline-block; } }
    .navigation--user-menu > .navigation--main {
      margin: 0; }
  .navigation__social {
    margin: 0 11px 0 0;
    color: #FFFFFF;
    font-size: 34px;
    transition: color .25s; }
    .navigation__social:focus, .navigation__social:hover {
      text-decoration: none; }

.new-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background-color: #FFFFFF;
  color: #000000;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  body.new-menu-search .new-header::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: none;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(55, 55, 55, 0.5); }
    @media screen and (min-width: 64em) {
      body.new-menu-search .new-header::before {
        display: block; } }
  @media screen and (min-width: 64em) {
    body.new-menu-search .new-header .new-header__item--search {
      z-index: 12; } }
  body.new-menu-search .new-header .new-header__item--search::before {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #FFFFFF; }
    @media screen and (min-width: 64em) {
      body.new-menu-search .new-header .new-header__item--search::before {
        display: block; } }
  @media screen and (min-width: 64em) {
    .new-header {
      justify-content: flex-start; } }
  .new-header__container {
    display: flex;
    height: 44px;
    margin: 0 auto;
    padding: 0;
    justify-content: space-between;
    align-items: stretch;
    flex-flow: row nowrap;
    align-content: center; }
    @media screen and (min-width: 64em) {
      .new-header__container {
        width: 950px;
        height: 72px;
        padding: 0; } }
    @media screen and (min-width: 75em) {
      .new-header__container {
        width: 100%;
        max-width: 1150px;
        padding: 0; } }
  .new-header__logo {
    display: block; }
    @media screen and (min-width: 64em) {
      .new-header__logo {
        width: 100%; } }
  .new-header__item {
    display: flex;
    padding: 0 10px;
    align-items: center;
    flex-direction: row; }
    .new-header__item--menu {
      display: none;
      align-items: stretch; }
      @media screen and (min-width: 64em) {
        .new-header__item--menu {
          display: flex;
          padding-right: 0;
          padding-left: 15px;
          flex-grow: 1;
          order: 1; } }
    .new-header__item--spacer {
      display: none; }
      @media screen and (min-width: 64em) {
        .new-header__item--spacer {
          display: block;
          flex-grow: 1;
          order: 2; } }
    .new-header__item--logo {
      position: relative;
      z-index: 4;
      margin-bottom: 0;
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
      justify-content: center;
      flex-grow: 1; }
      @media screen and (min-width: 64em) {
        .new-header__item--logo {
          margin-bottom: 0;
          flex-grow: 0;
          order: 0;
          -ms-flex: 2.5; } }
    .new-header__item--favorites {
      position: relative;
      padding-right: 0;
      padding-left: 23px; }
      @media screen and (min-width: 64em) {
        .new-header__item--favorites {
          padding: 0;
          order: 3; }
          .new-header__item--favorites .new-header__link:hover {
            color: #565656; } }
      .new-header__item--favorites .new-header__link {
        text-decoration: none; }
        @media screen and (min-width: 64em) {
          .new-header__item--favorites .new-header__link {
            padding: 20px 20px 15px; } }
      .new-header__item--favorites--customer {
        visibility: hidden;
        opacity: 0;
        pointer-events: none; }
    .new-header__item--search {
      padding-left: 0; }
      @media screen and (min-width: 64em) {
        .new-header__item--search {
          padding: 0;
          order: 4; }
          .new-menu-search .new-header__item--search {
            box-shadow: 0 2px 0 0 #FFFFFF; } }
    .new-header__item--user-menu {
      margin-left: 5px;
      padding-left: 0; }
      @media screen and (min-width: 64em) {
        .new-header__item--user-menu {
          margin-left: 0;
          padding-right: 22px;
          padding-left: 10px;
          order: 4; }
          .new-header__item--user-menu .navigation--sub {
            text-align: left;
            direction: rtl; }
          .new-header__item--user-menu .navigation__item {
            direction: ltr; } }
    .new-header__item--lang-select {
      margin-top: 5px;
      order: 10; }
      @media screen and (min-width: 64em) {
        .new-header__item--lang-select {
          padding-right: 0;
          padding-left: 20px; } }
    .new-header__item--mobile {
      display: flex;
      padding-left: 7px; }
      @media screen and (min-width: 64em) {
        .new-header__item--mobile {
          display: none; } }
  .new-header__menu-icon--open {
    display: none; }
  .new-header__menu-nav {
    transition: transform .25s; }
    .new-header__menu-nav--left {
      transform: translateX(-120%); }
      @media screen and (min-width: 64em) {
        .new-header__menu-nav--left {
          transform: translateX(0); } }
    .new-header__menu-nav--right {
      transform: translateX(120%); }
      @media screen and (min-width: 64em) {
        .new-header__menu-nav--right {
          transform: translateX(0); } }
  .new-header__image {
    min-width: 34px;
    max-width: 100px;
    max-height: 34px; }
    @media screen and (min-width: 64em) {
      .new-header__image {
        min-width: 60px;
        max-width: 120px;
        max-height: 61px; } }
    .new-header__image--wide {
      width: 120px; }
  .new-header__menu-toggle {
    min-width: 33px;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    background-color: transparent;
    font-size: 20px;
    transition: border-color .25s; }
    @media screen and (min-width: 64em) {
      .new-header__menu-toggle {
        display: none;
        min-width: 50px; } }
    .new-header .new-header__menu-toggle--open .nyc-icon::before {
      top: 0; }
    .new-header__menu-toggle--open .new-header__menu-icon--closed {
      display: none; }
      @media screen and (min-width: 64em) {
        .new-header__menu-toggle--open .new-header__menu-icon--closed {
          display: block; } }
    .new-header__menu-toggle--open .new-header__menu-icon--open {
      display: block;
      padding-top: 4px; }
      @media screen and (min-width: 64em) {
        .new-header__menu-toggle--open .new-header__menu-icon--open {
          display: none; } }
    .new-header__menu-toggle--open ~ .new-header__menu-nav {
      transform: translateX(0); }
      @media screen and (min-width: 64em) {
        .new-header__menu-toggle--open ~ .new-header__menu-nav {
          display: flex; } }
    @media screen and (min-width: 64em) {
      .new-header__menu-toggle--desktop {
        display: block; }
        .new-header__menu-toggle--desktop ~ .navigation {
          overflow: visible; }
        .new-header__menu-toggle--desktop:hover {
          border-color: #F77F00; } }
  .new-header__link {
    color: #111111; }
    .new-header__link:hover {
      color: #F77F00; }
  .new-header__btn {
    display: block;
    width: 100%;
    min-height: 44px;
    padding: 0 1em;
    border: 1px solid transparent;
    border-radius: 0;
    outline: 0;
    background-color: #F77F00;
    color: #FFFFFF;
    font-family: 'Work Sans', sans-serif;
    font-size: 1em;
    font-weight: 900;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: border .25s ease-in-out, background-color .25s ease-in-out, color .25s ease-in-out;
    cursor: pointer;
    user-select: none; }
    .new-header__btn:hover, .new-header__btn:focus {
      border: 1px solid #F77F00;
      background-color: #FFFFFF;
      color: #F77F00;
      text-decoration: none; }
    .new-header__btn--black {
      background-color: #111111;
      color: #FFFFFF; }
      .new-header__btn--black:hover, .new-header__btn--black:focus {
        border: 1px solid #111111;
        background-color: #FFFFFF;
        color: #111111;
        text-decoration: none; }
    .new-header__btn--drop::after {
      position: absolute;
      top: 50%;
      right: 2em;
      margin-top: -.5em;
      color: currentColor; }
  .new-header__favorites-count {
    position: absolute;
    top: -0.3125rem;
    right: -0.48125rem;
    display: block;
    min-width: 18px;
    padding: 1px 3px;
    border-radius: 100%;
    background-color: #F77F00;
    color: #FFFFFF;
    font-size: 11px;
    line-height: 1.5;
    text-align: center; }
  .new-header__favorites-icon {
    position: relative;
    font-size: 22px; }
  .new-header .nyc-icon {
    padding-top: 2px;
    line-height: 1.875rem; }
    .new-header .nyc-icon::before {
      position: relative;
      top: 2px; }
      @media screen and (min-width: 64em) {
        .new-header .nyc-icon::before {
          position: static; } }

.search {
  z-index: 3; }
  @media screen and (min-width: 64em) {
    .search {
      position: relative;
      z-index: 1; } }
  .search--all-site .search--all-site {
    display: block; }
  .search--job-listing .search--job-listing {
    display: block; }
  .search__toggle {
    position: relative;
    padding: 0 16px 0 0;
    border: 0;
    outline: none;
    background: transparent;
    font-size: 20px; }
    @media screen and (min-width: 64em) {
      .search__toggle {
        padding: 20px 20px 15px 18px; } }
    .search__toggle:hover {
      color: #565656; }
    .search__toggle .nyc-icon::before {
      top: 3px; }
  .search__box {
    position: fixed;
    top: 44px;
    left: 0;
    z-index: 998;
    display: flex;
    width: 100%;
    min-height: calc(100vh - 44px);
    background-color: #FAFAFA;
    transform: translateY(-120%);
    transition: transform .25s, opacity .25s, visibility .25s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) inset; }
    @media screen and (min-width: 48em) {
      .search__box .card__body {
        padding: 1rem; } }
    @media screen and (min-width: 64em) {
      .search__box {
        top: 4.5rem;
        visibility: hidden;
        width: 28.125rem;
        min-height: auto;
        padding-top: 0;
        background-color: #FFFFFF;
        opacity: 0;
        box-shadow: none; } }
    .search__box--open {
      transform: translateY(0); }
      @media screen and (min-width: 64em) {
        .search__box--open {
          visibility: visible;
          opacity: 1; } }
  .search__selector {
    width: 50%;
    margin-bottom: 15px;
    flex-basis: 50%; }
    .search__selector:first-child .search__button {
      border-right: 0; }
  .search__button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    border: 1px solid #E8E8E8;
    border-radius: 0;
    outline: 0;
    background: transparent;
    color: #B2B2B2;
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
    font-weight: 900;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase; }
  .search__form {
    display: none;
    width: 100%; }
  .search__input {
    display: block;
    width: 100%;
    min-height: 3.14286rem;
    margin-bottom: 1rem;
    padding: 0.92857rem 1.14286rem;
    border: 1px solid #E8E8E8;
    border-radius: 0;
    outline: none;
    background-color: #FFFFFF;
    color: #000000;
    font-size: 0.91429rem;
    transition: border ease-in-out .25s; }
    @media screen and (min-width: 48em) {
      .search__input {
        min-height: 2.75rem;
        padding: 0.8125rem 1rem;
        font-size: 0.875rem; } }
    .search__input::-webkit-input-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .search__input::-moz-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .search__input::-moz-placeholder {
      color: #717171;
      text-overflow: ellipsis !important;
      opacity: 1; }
    .search__input:-ms-input-placeholder {
      color: #717171;
      text-overflow: ellipsis !important; }
    .search__input:focus {
      border-color: #000000; }

.user-menu {
  cursor: pointer;
  align-items: stretch; }
  @media screen and (min-width: 64em) {
    .user-menu {
      border-bottom: 3px solid transparent;
      transition: all .25s; }
      .user-menu-toggle--logged-out .navigation__item, .user-menu.user-menu-toggle--logged-out {
        border-bottom: 0; }
      .user-menu .new-header__menu-toggle--desktop {
        pointer-events: none; }
      .user-menu:hover, .user-menu--active {
        border-bottom-color: #F77F00;
        transition: all .25s; }
      .user-menu--active .navigation__drop {
        pointer-events: none; }
      .user-menu--active .navigation .navigation__container {
        visibility: visible;
        opacity: 1; } }
  .user-menu--anonymus {
    overflow: visible;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) inset; }
    @media screen and (min-width: 64em) {
      .user-menu--anonymus {
        margin-right: -22px;
        box-shadow: none; } }
    .user-menu--anonymus .new-header__btn {
      min-width: 135px;
      min-height: 36px;
      font-size: 12px; }
    .user-menu--anonymus a.new-header__btn {
      padding: .95em 1em .85em; }
    @media screen and (min-width: 64em) {
      .user-menu--anonymus .navigation__item {
        margin-right: 5px;
        padding: 0; }
        .user-menu--anonymus .navigation__item:last-child {
          margin-right: 0;
          padding: 0; } }
  .user-menu__image-wrap {
    position: relative;
    top: 1px; }
    .user-menu__image-wrap::after {
      display: none !important; }
      @media screen and (min-width: 64em) {
        .user-menu__image-wrap::after {
          position: absolute;
          top: 1.625rem;
          right: -1rem;
          display: block !important;
          margin: 0; } }
  .user-menu__image {
    width: 33px;
    height: 33px;
    border-radius: 100%; }
    @media screen and (min-width: 64em) {
      .user-menu__image {
        width: 60px;
        height: 60px; } }
  .user-menu__notify {
    position: absolute;
    top: 0;
    right: -10px;
    display: block;
    color: #FFFFFF;
    font-size: 19px;
    transform: rotate(180deg); }
    @media screen and (min-width: 64em) {
      .user-menu__notify {
        top: 7px;
        right: -9px; } }
    .user-menu__notify::before {
      top: 0;
      border-radius: 100%; }
    .user-menu__notify::after {
      position: absolute;
      top: 5px;
      right: 1px;
      z-index: -1;
      display: block;
      width: 17px;
      height: 17px;
      border-radius: 100%;
      content: '';
      background: #F77F00; }
      @media screen and (min-width: 64em) {
        .user-menu__notify::after {
          top: 3px; } }
  @media screen and (min-width: 64em) {
    .user-menu--logged-in {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0; } }
  .user-menu .new-header__menu-icon--open {
    width: 33px;
    padding-top: 5px; }
  @media screen and (min-width: 64em) {
    .user-menu .navigation__container {
      position: absolute; } }

.menu-select {
  display: none;
  color: #B2B2B2;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900; }
  @media screen and (min-width: 64em) {
    .menu-select {
      display: flex; } }
  .menu-select--wide .menu-select__list {
    width: 100%; }
  .menu-select__list {
    position: relative;
    display: flex;
    min-width: 50px;
    height: 36px;
    margin: 0;
    font-weight: 900;
    text-transform: uppercase;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: flex-start; }
    .menu-select__list .menu-select__item--login {
      display: none; }
    .menu-select__list .menu-select__item:last-of-type {
      display: none; }
    .menu-select__list--visible .menu-select__item--login, .menu-select__list:hover .menu-select__item--login {
      display: flex; }
    .menu-select__list--visible .menu-select__item:last-of-type, .menu-select__list:hover .menu-select__item:last-of-type {
      display: flex; }
    .menu-select__list::after {
      position: absolute;
      top: 14px;
      right: 6px; }
    .menu-select__list--right {
      text-align: right; }
  .menu-select__link {
    display: block;
    padding: .7em 1em;
    color: #B2B2B2;
    font-weight: 900;
    text-align: center;
    text-decoration: none;
    flex-grow: 1; }
    .menu-select__link:focus, .menu-select__link:hover {
      background-color: #FFFFFF;
      color: #F77F00;
      text-decoration: none; }
  .menu-select__item {
    display: flex;
    width: 100%;
    min-height: 36px;
    border: 1px solid #C3C3C3;
    border-top-width: 0;
    background-color: #FFFFFF;
    font-size: 12px;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between; }
    .menu-select__item:first-child {
      border-top-width: 1px;
      justify-content: center; }
    .menu-select__item--borderless {
      border: 0; }
  .menu-select__label {
    padding: 0 10px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    text-transform: none; }

.c-notify {
  display: flex;
  width: calc(100% + 50px);
  margin: -22px -25px 6px;
  padding: 18px 29px;
  background-color: #000000;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 64em) {
    .c-notify {
      width: 100%;
      margin: 0; } }
  .c-notify__group {
    display: flex;
    color: #FFFFFF;
    flex-flow: row;
    justify-content: space-between;
    align-items: center; }
    @media screen and (min-width: 64em) {
      .c-notify__group {
        width: 500px; } }
  .c-notify__text {
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.2px;
    line-height: 1.36;
    text-align: left;
    text-transform: none;
    flex-grow: 1; }
    @media screen and (min-width: 64em) {
      .c-notify__text {
        width: 48%; } }
  .c-notify__icon {
    padding-right: 20px;
    font-size: 20px; }
    .c-notify__icon .nyc-icon {
      display: inline-block;
      transform: rotate(180deg); }
  .c-notify__btn {
    width: 100%;
    margin-top: 11px; }
    @media screen and (min-width: 64em) {
      .c-notify__btn {
        width: auto;
        margin-top: 0;
        padding-left: 20px; } }
    .c-notify__btn .new-header__btn {
      min-width: 263px;
      padding: 14px;
      font-size: 14px; }
      .c-notify__btn .new-header__btn:focus, .c-notify__btn .new-header__btn:hover {
        border-color: #FFFFFF;
        background-color: #000000;
        color: #FFFFFF; }

body.new-menu-search {
  position: relative;
  overflow: visible;
  height: 100vh; }
  @media screen and (min-width: 48em) {
    body.new-menu-search {
      overflow: hidden; } }
  body.new-menu-search::before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(55, 55, 55, 0.5); }
    @media screen and (min-width: 48em) {
      body.new-menu-search::before {
        display: block; } }

ul.navigation--main {
  margin-top: 0;
  margin-bottom: 0; }

.job-list__item, .poll-list__item label, .poll-list__result {
  position: relative;
  display: block;
  padding: 1.25rem 3.75rem 1.25rem 1.875rem;
  border: 1px solid #E8E8E8;
  border-bottom: 0;
  background-color: #FFFFFF;
  font-size: 0.875rem;
  font-weight: 100;
  line-height: 1.43;
  transition: border-color 0.12s ease-in-out, background-color 0.12s ease-in-out, color 0.12s ease-in-out;
  box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.04);
  cursor: pointer; }
  @media screen and (min-width: 48em) {
    .job-list__item, .poll-list__item label, .poll-list__result {
      margin-bottom: 0.625rem;
      padding-left: 1.25rem;
      border-bottom: 1px solid #E8E8E8;
      font-size: 1rem;
      font-weight: 600; } }
  .job-list__item:last-child, .poll-list__item label:last-child, .poll-list__result:last-child {
    border-bottom: 1px solid #E8E8E8; }
  .job-list__item:hover, .poll-list__item label:hover, .poll-list__result:hover {
    border-color: #F77F00;
    background-color: #F77F00;
    color: #FFFFFF;
    -webkit-tap-highlight-color: transparent; }

.top-badge, .job-list__top-badge {
  background-color: #111111;
  font-size: 0.875rem; }
  @media screen and (min-width: 30em) {
    .top-badge, .job-list__top-badge {
      font-size: 0.75rem; } }
  @media screen and (min-width: 48em) {
    .top-badge, .job-list__top-badge {
      font-size: 1rem; } }

.top-badge {
  position: relative;
  padding: 0.125rem 0.25rem; }
  @media screen and (min-width: 48em) {
    .top-badge {
      position: relative;
      padding: .3rem .6rem .4rem; } }

.list__title {
  padding: 1.14286rem;
  padding-bottom: 0.5rem; }
  @media screen and (min-width: 48em) {
    .list__title {
      padding: 2rem;
      padding-bottom: 1rem; } }
  .list__title + p {
    margin-top: 0;
    padding: 1.14286rem;
    padding-top: 0; }
    @media screen and (min-width: 48em) {
      .list__title + p {
        padding: 2rem;
        padding-top: 0; } }

.list--arrows li {
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem; }
  .list--arrows li > a {
    text-decoration: none; }
  .list--arrows li::before {
    margin-top: 2px;
    margin-right: .5rem;
    color: #F77F00;
    font-size: 80%;
    line-height: inherit; }

.list--links {
  margin-top: 0; }
  .list--links data {
    margin-left: 0.71429rem;
    font-weight: 700; }
    @media screen and (min-width: 48em) {
      .list--links data {
        margin-left: 0.625rem; } }
  .list--links li {
    display: flex;
    width: 100%;
    padding: 1.14286rem;
    border-top: 1px solid #E8E8E8; }
    @media screen and (min-width: 48em) {
      .list--links li {
        padding: 1rem 2rem; }
        .list--links li:last-of-type {
          padding-bottom: 2rem; } }
    .list--links li::before {
      margin-top: 2px;
      margin-right: .5rem;
      color: #F77F00;
      font-size: 80%;
      line-height: inherit; }
    .list--links li:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer; }
      .list--links li:hover a {
        color: #565656; }
  .list--links a,
  .list--links span {
    display: flex;
    overflow: hidden;
    width: 100%;
    text-decoration: none;
    text-overflow: ellipsis;
    justify-content: space-between; }

.job-list__item {
  display: flex;
  padding: 0;
  flex-direction: row; }
  .job-list__item .favorite-icon::before {
    color: #B2B2B2; }
  .job-list__item--top {
    position: relative;
    border-right: 4px solid #F77F00; }
    @media screen and (min-width: 48em) {
      .job-list__item--top {
        border-right: 0; } }
    .job-list__item--top .job-list__content {
      padding: 1.25rem 0.9375rem 1.25rem 2.1875rem;
      font-weight: 600;
      -webkit-tap-highlight-color: transparent; }
      @media screen and (min-width: 48em) {
        .job-list__item--top .job-list__content {
          padding: 1.25rem 6.25rem 1.25rem 1.25rem;
          border-left: 4px solid #F77F00;
          background-color: transparent;
          color: #111111; } }
    .job-list__item--top .job-list__title {
      font-weight: 600; }
      @media screen and (min-width: 48em) {
        .job-list__item--top .job-list__title {
          margin-bottom: 0.3125rem;
          color: #F77F00; } }
    .job-list__item--top .favorite-button {
      padding-right: 3px; }
  .job-list__item:hover .job-list__content {
    color: #FFFFFF; }
  .job-list__item:hover .job-list__title {
    color: #FFFFFF; }
  .job-list__item:hover .job-list__type {
    color: #FFFFFF; }
  .job-list__item:hover .job-list__description {
    color: #FFFFFF; }
  .job-list__item:hover .job-list__top-badge {
    background-color: #111111; }
  .job-list__item:hover .favorite-icon::before {
    color: #FFFFFF; }

.job-list__content {
  display: flex;
  width: 100%;
  padding: 1.25rem 0.9375rem 0.9375rem 1.5625rem;
  color: #111111;
  font-weight: 400;
  text-decoration: none;
  flex-direction: row;
  flex-wrap: wrap; }
  @media screen and (min-width: 48em) {
    .job-list__content {
      padding: 1.25rem 6.25rem 1.25rem 1.25rem;
      font-weight: 600; } }
  .job-list__content:hover {
    color: #111111;
    text-decoration: none; }
  .job-list__content:focus {
    opacity: 1;
    color: #111111; }

.job-list__top-badge {
  position: absolute;
  top: 50%;
  left: -18px;
  display: block;
  padding: 0.25rem 0.3125rem;
  font-size: 0.625rem;
  transform: translateY(-50%) rotate(-90deg); }
  @media screen and (min-width: 48em) {
    .job-list__top-badge {
      right: 70px;
      left: inherit;
      padding: 0.1875rem 0.5rem;
      font-size: 1.0625rem;
      transform: translateY(-50%) rotate(0); } }

.job-list__title {
  width: 100%;
  margin: 0;
  font-size: 0.875rem;
  text-transform: none; }
  @media screen and (min-width: 48em) {
    .job-list__title {
      margin: 0;
      color: #F77F00;
      font-size: 1.0625rem;
      font-weight: 600; } }

.job-list__type-group {
  display: none;
  width: 100%;
  margin: 0;
  padding: 0.625rem 0;
  font-weight: 600; }
  @media screen and (min-width: 48em) {
    .job-list__type-group {
      display: flex;
      flex-basis: 100%; } }

.job-list__type {
  display: none;
  font-size: 0.875rem; }
  @media screen and (min-width: 48em) {
    .job-list__type {
      display: flex; } }

.job-list__description {
  display: none;
  color: #565656;
  font-size: 0.875rem;
  font-weight: normal; }
  @media screen and (min-width: 48em) {
    .job-list__description {
      display: block; } }

.job-list--extended .job-list__content {
  flex-direction: row;
  flex-wrap: wrap; }

@media screen and (min-width: 48em) {
  .job-list--extended .job-list__top-badge {
    position: static;
    margin: 0;
    font-size: 0.875rem;
    transform: translateY(0) rotate(0); } }

.job-list--extended .favorite-button {
  width: 50px;
  padding: 0; }
  @media screen and (min-width: 48em) {
    .job-list--extended .favorite-button {
      width: 75px;
      height: 75px;
      padding: 25px; } }

.poll-list__question {
  margin: 0 0 20px;
  letter-spacing: 0.00625rem;
  line-height: 1.41; }
  @media screen and (min-width: 48em) {
    .poll-list__question {
      margin-bottom: 30px; } }

.poll-list__item input {
  position: absolute;
  opacity: 0; }
  .poll-list__item input:checked + label {
    border-color: #F77F00;
    background-color: #F77F00;
    color: #FFFFFF; }

.poll-list__item label {
  padding: 23px 15px;
  font-weight: 400; }
  @media screen and (min-width: 48em) {
    .poll-list__item label:hover {
      border-color: #111111;
      background-color: #111111; } }
  .poll-list__item label:hover {
    background-color: #111111;
    color: #FFFFFF; }

.poll-list__result,
.poll-list__item label {
  padding: 23px 15px; }

.poll-list__result, .poll-list__result:hover {
  background-color: #FFFFFF;
  color: #111111; }

.poll-list__result {
  position: relative;
  margin-bottom: 0;
  padding: 23px 15px;
  cursor: auto; }
  @media screen and (min-width: 48em) {
    .poll-list__result {
      margin-bottom: 10px; } }
  .poll-list__result:last-child {
    margin-bottom: 0; }
  .poll-list__result:hover {
    border-color: #E8E8E8; }

.poll-list__result--submitted, .poll-list__result--submitted:hover {
  background-color: #111111;
  color: #FFFFFF; }

.poll-list__result--submitted .poll-list__result-percentage {
  color: #FFFFFF; }

.poll-list__result-value {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none; }
  .poll-list__result-value::-webkit-progress-bar {
    height: 100%;
    background-color: transparent; }
  .poll-list__result-value::-webkit-progress-value {
    background-color: #F77F00; }

.poll-list__result-label {
  width: 75%; }
  @media screen and (min-width: 30em) {
    .poll-list__result-label {
      width: 90%; } }

.poll-list__result-percentage {
  position: absolute;
  top: 50%;
  right: 20px;
  color: #F77F00;
  font-weight: bold;
  transform: translateY(-50%); }
  @media screen and (min-width: 30em) {
    .poll-list__result-percentage {
      right: 18px; } }

.editable-alert-list__item {
  display: flex;
  height: 4.25rem;
  border-bottom: 1px solid #F4F4F4;
  font-size: 0.875rem;
  align-content: center;
  justify-content: space-between; }
  .editable-alert-list__item__text {
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 600;
    flex: 1; }
    @media screen and (min-width: 48em) {
      .editable-alert-list__item__text {
        flex: 2; } }
  .editable-alert-list__item__icon {
    padding-right: 0.625rem;
    color: #F77F00;
    font-size: 1.3125rem; }
    .editable-alert-list__item__icon--disabled {
      color: #B2B2B2; }
  .editable-alert-list__item__frequency {
    padding: 0.625rem;
    flex: 2;
    align-content: center;
    justify-content: space-between; }
    @media screen and (min-width: 48em) {
      .editable-alert-list__item__frequency {
        width: 0;
        flex: 1; } }
  .editable-alert-list__item--disabled {
    color: #B2B2B2; }

@media screen and (min-width: 48em) {
  dl:not(.row):not(.flex-row) {
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: auto auto;
    grid-gap: 0.5rem 3.75rem; } }
  @media screen and (min-width: 48em) and (-ms-high-contrast: none), screen and (min-width: 48em) and (-ms-high-contrast: active) {
    dl:not(.row):not(.flex-row) {
      display: -ms-grid;
      margin-top: 1rem;
      -ms-grid-columns: max-content auto;
      -ms-grid-rows: auto auto; } }

@media screen and (min-width: 48em) {
  dl:not(.row):not(.flex-row) > dd,
  dl:not(.row):not(.flex-row) > div {
    -ms-grid-column: 2; } }

@media screen and (min-width: 48em) {
  dl:not(.row):not(.flex-row) dt {
    -ms-grid-column: 1; } }

@media screen and (-ms-high-contrast: none) and (min-width: 48em), screen and (-ms-high-contrast: active) and (min-width: 48em) {
  dl:not(.row):not(.flex-row) dt {
    margin-right: 3.75rem; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(1),
    dl:not(.row):not(.flex-row) dt:nth-of-type(1) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(1) + div {
      margin-top: -1rem;
      -ms-grid-row: 1; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(2),
    dl:not(.row):not(.flex-row) dt:nth-of-type(2) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(2) + div {
      -ms-grid-row: 2; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(3),
    dl:not(.row):not(.flex-row) dt:nth-of-type(3) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(3) + div {
      -ms-grid-row: 3; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(4),
    dl:not(.row):not(.flex-row) dt:nth-of-type(4) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(4) + div {
      -ms-grid-row: 4; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(5),
    dl:not(.row):not(.flex-row) dt:nth-of-type(5) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(5) + div {
      -ms-grid-row: 5; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(6),
    dl:not(.row):not(.flex-row) dt:nth-of-type(6) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(6) + div {
      -ms-grid-row: 6; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(7),
    dl:not(.row):not(.flex-row) dt:nth-of-type(7) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(7) + div {
      -ms-grid-row: 7; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(8),
    dl:not(.row):not(.flex-row) dt:nth-of-type(8) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(8) + div {
      -ms-grid-row: 8; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(9),
    dl:not(.row):not(.flex-row) dt:nth-of-type(9) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(9) + div {
      -ms-grid-row: 9; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(10),
    dl:not(.row):not(.flex-row) dt:nth-of-type(10) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(10) + div {
      -ms-grid-row: 10; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(11),
    dl:not(.row):not(.flex-row) dt:nth-of-type(11) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(11) + div {
      -ms-grid-row: 11; }
    dl:not(.row):not(.flex-row) dt:nth-of-type(12),
    dl:not(.row):not(.flex-row) dt:nth-of-type(12) + dd,
    dl:not(.row):not(.flex-row) dt:nth-of-type(12) + div {
      -ms-grid-row: 12; } }

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  dl:not(.row):not(.flex-row) dt,
  dl:not(.row):not(.flex-row) dt + dd,
  dl:not(.row):not(.flex-row) dt + div {
    margin-bottom: 0; } }
  @media screen and (-ms-high-contrast: none) and (min-width: 48em), screen and (-ms-high-contrast: active) and (min-width: 48em) {
    dl:not(.row):not(.flex-row) dt,
    dl:not(.row):not(.flex-row) dt + dd,
    dl:not(.row):not(.flex-row) dt + div {
      margin-top: 1rem; } }

dt {
  font-weight: 700; }

dd {
  margin-left: 0; }

dl.flex-row {
  flex-wrap: wrap; }
  dl.flex-row dd {
    margin-right: 80px; }

.breadcrumb__links {
  position: relative;
  overflow-y: hidden;
  padding: 1rem 1rem 0.375rem;
  padding-left: 0;
  border-bottom: 1px solid #E8E8E8;
  white-space: nowrap;
  background: #FFFFFF;
  text-align: left;
  direction: rtl; }
  @media screen and (max-width: 767px) {
    .breadcrumb__links {
      overflow-x: auto; } }
  @media screen and (min-width: 64em) {
    .breadcrumb__links {
      padding-bottom: 1rem;
      border-bottom: unset;
      background: transparent; } }
  @media screen and (min-width: 64em) {
    .breadcrumb__links {
      text-align: left; }
      .breadcrumb__links:first-child::before {
        background: none; } }
  .breadcrumb__links li {
    z-index: 2;
    display: inline; }
    @media screen and (max-width: 1023px) {
      .breadcrumb__links li {
        padding-bottom: 0.3125rem; } }
    .breadcrumb__links li a {
      font-weight: 700;
      text-decoration: underline; }
    @media screen and (max-width: 767px) {
      .breadcrumb__links li:first-of-type {
        padding-left: 1rem; } }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .breadcrumb__links li:first-of-type {
        padding-left: 2rem; } }
    @media screen and (max-width: 1023px) {
      .breadcrumb__links li:last-of-type {
        border-bottom: 4px solid #F77F00; } }
    .breadcrumb__links li:last-of-type a {
      font-weight: normal;
      text-decoration: none; }
  .breadcrumb__links span {
    font-size: 0.625rem; }

.breadcrumb__container {
  position: relative; }

.breadcrumb__fade {
  position: relative;
  pointer-events: none; }
  @media screen and (max-width: 767px) {
    .breadcrumb__fade--effect {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 4;
      width: 75px;
      height: 100%;
      min-height: 30px;
      max-height: 20px;
      content: '';
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); } }

.tabs {
  height: 4.57143rem;
  border-bottom: solid 1px #E0E0E1; }
  @media screen and (min-width: 48em) {
    .tabs {
      height: 4rem; } }
  .tabs > ul,
  .tabs > ul > li {
    height: inherit; }
  .tabs--horizontal-scroll {
    position: relative;
    overflow: hidden; }
    @media screen and (max-width: 64em) {
      .tabs--horizontal-scroll .tabs__container {
        position: absolute;
        top: 0;
        bottom: -0.9375rem;
        left: 0;
        overflow-x: scroll;
        overflow-y: hidden;
        width: 100%;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch; } }

.tab {
  display: inline-block;
  padding: 0 1.14286rem; }
  @media screen and (min-width: 48em) {
    .tab {
      padding: 0 1rem; } }
  .tab__text {
    display: block;
    padding: 2rem 0 1.21429rem;
    border: 0;
    border-bottom: 0.25rem solid transparent;
    background: transparent;
    color: #111111;
    line-height: 1rem;
    text-decoration: none;
    text-shadow: none;
    box-shadow: none; }
    @media screen and (min-width: 48em) {
      .tab__text {
        padding: 1.75rem 0 0.9375rem;
        line-height: 1rem; } }
    .tab__text:hover {
      color: #F77F00; }
  .tab--active {
    pointer-events: none; }
    .tab--active .tab__text {
      border-bottom: 0.25rem solid #111111; }

.tags {
  display: flex;
  margin-bottom: -0.57143rem;
  font-size: 1rem;
  line-height: 1rem;
  flex-wrap: wrap;
  flex-direction: row; }
  @media screen and (min-width: 48em) {
    .tags {
      margin-bottom: -0.5rem;
      font-size: 0.875rem;
      line-height: 0.875rem; } }
  .tags li {
    margin: 0 0.57143rem 0.57143rem 0;
    padding: 0;
    font-weight: 500; }
    @media screen and (min-width: 48em) {
      .tags li {
        margin: 0 0.5rem 0.5rem 0; } }
  .tags > a,
  .tags li:not(.tags__item) > a:not(.nyc-icon), .tags__item {
    display: block;
    padding: 0.42857rem 0.71429rem;
    border: 1px solid #111111;
    background: #111111;
    color: #FFFFFF;
    font-weight: 500;
    text-decoration: none; }
    @media screen and (min-width: 48em) {
      .tags > a,
      .tags li:not(.tags__item) > a:not(.nyc-icon), .tags__item {
        padding: 0.5rem 0.75rem;
        transition: border 0.12s ease-in-out, background-color 0.12s ease-in-out, color 0.12s ease-in-out; }
        .tags > a:hover,
        .tags li:not(.tags__item) > a:not(.nyc-icon):hover, .tags__item:hover {
          background: transparent;
          color: #111111; } }
  .tags__item {
    cursor: pointer; }
    @media screen and (min-width: 48em) {
      .tags__item > a {
        font-size: 0.6875rem; }
      .tags__item a {
        transition: border 0.12s ease-in-out, background-color 0.12s ease-in-out, color 0.12s ease-in-out; }
      .tags__item:hover a {
        color: #111111; } }
    .tags__item > a {
      position: relative;
      top: 1px;
      color: #FFFFFF;
      font-size: 0.78571rem;
      vertical-align: middle; }
  .tags--light > a,
  .tags--light li:not(.tags__item) > a:not(.nyc-icon),
  .tags--light .tags__item {
    border-color: #B2B2B2;
    background: #B2B2B2;
    color: #FFFFFF; }
    .tags--light > a:hover,
    .tags--light li:not(.tags__item) > a:not(.nyc-icon):hover,
    .tags--light .tags__item:hover {
      background: transparent;
      color: #B2B2B2; }
  .tags--inverted.tags--light > a,
  .tags--inverted.tags--light li:not(.tags__item) > a:not(.nyc-icon),
  .tags--inverted.tags--light .tags__item {
    background: transparent;
    color: #B2B2B2; }
    @media screen and (min-width: 48em) {
      .tags--inverted.tags--light > a:hover,
      .tags--inverted.tags--light li:not(.tags__item) > a:not(.nyc-icon):hover,
      .tags--inverted.tags--light .tags__item:hover {
        color: #FFFFFF; } }
    .tags--inverted.tags--light > a:hover,
    .tags--inverted.tags--light li:not(.tags__item) > a:not(.nyc-icon):hover,
    .tags--inverted.tags--light .tags__item:hover {
      border-color: #B2B2B2;
      background: #B2B2B2;
      color: #FFFFFF; }
  @media screen and (min-width: 48em) {
    .tags--inverted.tags--light li:not(.tags__item) > a:not(.nyc-icon):hover a,
    .tags--inverted.tags--light .tags__item:hover a {
      color: #FFFFFF; } }
  .tags--inverted.tags--light .tags__item > a {
    color: #B2B2B2; }
  .tags--search-results .tags__item {
    cursor: default; }
    .tags--search-results .tags__item:hover {
      background: #111111;
      color: #FFFFFF; }
      .tags--search-results .tags__item:hover a {
        color: #FFFFFF; }
    .tags--search-results .tags__item a {
      transition: .2s ease-in-out; }
      .tags--search-results .tags__item a:hover {
        color: #F77F00; }

h1 + .tags,
h2 + .tags,
h3 + .tags,
.h1 + .tags,
.h2 + .tags,
.h3 + .tags {
  margin-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    h1 + .tags,
    h2 + .tags,
    h3 + .tags,
    .h1 + .tags,
    .h2 + .tags,
    .h3 + .tags {
      margin-top: 1rem; }
      h1 + .tags--search-results,
      h2 + .tags--search-results,
      h3 + .tags--search-results,
      .h1 + .tags--search-results,
      .h2 + .tags--search-results,
      .h3 + .tags--search-results {
        margin-top: 0.5rem; }
        h1 + .tags--search-results + p,
        h2 + .tags--search-results + p,
        h3 + .tags--search-results + p,
        .h1 + .tags--search-results + p,
        .h2 + .tags--search-results + p,
        .h3 + .tags--search-results + p {
          margin-top: 1rem; } }

.ribbon {
  position: relative;
  z-index: 1;
  min-height: 190px;
  padding: 45px 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }
  .ribbon .container-fluid {
    padding-right: 2.28571rem;
    padding-left: 1.14286rem; }
    @media screen and (min-width: 48em) {
      .ribbon .container-fluid {
        padding-right: 0;
        padding-left: 0; } }

@media screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .ribbon .container-fluid {
    padding-right: 2.28571rem;
    padding-left: 1.14286rem; } }
  .ribbon--homepage {
    min-height: 350px; }
    @media screen and (min-width: 48em) {
      .ribbon--homepage {
        min-height: 397px;
        padding: 85px 0; } }
    @media screen and (min-width: 64em) {
      .ribbon--homepage {
        min-height: 329px; } }
  .ribbon__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    background-color: #E0E0E1;
    background-position: center 37.5%;
    background-size: cover; }
  .ribbon__heading {
    position: relative;
    display: inline;
    padding: 0 0.57143rem;
    background-color: #111111;
    color: #FFFFFF;
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.5rem;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 1.57143rem;
    text-transform: uppercase;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone; }
    @media screen and (min-width: 48em) {
      .ribbon__heading {
        padding: 0 0.5rem;
        font-size: 2.5625rem;
        line-height: 2.6875rem; } }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
      .ribbon__heading {
        padding: 0 0.57143rem; } }
  .ribbon--faded .ribbon__image {
    opacity: .5; }
  .ribbon--has-alert .ribbon__heading {
    opacity: 0;
    transition: all .8s ease; }
  .ribbon--has-alert .container-fluid {
    position: relative;
    padding: 0 16px !important; }
    @media screen and (min-width: 48em) {
      .ribbon--has-alert .container-fluid {
        padding: 0 !important; } }

.social-networks {
  display: flex;
  padding-top: 0.8125rem;
  color: #111111;
  flex-direction: row; }
  .social-networks__icon {
    padding-right: 0.5rem;
    color: inherit;
    font-size: 2rem; }
    @media screen and (min-width: 75em) {
      .social-networks__icon {
        font-size: 2.25rem; } }
  .social-networks--share .social-networks__icon {
    height: 1.5625rem;
    font-size: 1.5rem; }
    @media screen and (min-width: 75em) {
      .social-networks--share .social-networks__icon {
        font-size: 1.5rem; } }

main + footer .social-networks__icon {
  color: #FFFFFF; }

main + footer {
  position: relative;
  bottom: 0;
  left: 0;
  padding: 2.28571rem 0;
  background-color: #292929;
  color: #FFFFFF; }
  @media screen and (min-width: 64em) {
    main + footer {
      padding: 3rem 0; } }
  main + footer .input__label {
    font-weight: 700; }
  main + footer ul {
    margin-top: 0; }
  main + footer li {
    padding-top: 1.14286rem; }
    @media screen and (min-width: 48em) {
      main + footer li {
        padding-top: 1rem; } }
  main + footer a {
    color: #FFFFFF;
    text-decoration: none; }
    main + footer a:hover {
      color: #F77F00; }
  main + footer small {
    font-size: 1rem; }
    @media screen and (min-width: 64em) {
      main + footer small {
        font-size: 0.875rem; } }

.alert {
  position: relative;
  top: 0;
  z-index: 1;
  width: 100%;
  transition: all .5s ease; }
  .alert:not(.card__body) {
    margin-bottom: 20px;
    padding: 1.5rem; }
    @media screen and (min-width: 48em) {
      .alert:not(.card__body) {
        position: absolute;
        margin-bottom: 0; } }
  .alert > span {
    display: inline-block;
    margin-right: 12px; }
  .alert a {
    font-weight: 600; }
  .alert--info {
    background: #B9E5E7;
    color: #007D88; }
    .alert--info a {
      color: #007D88; }
  .alert--success {
    background: #B2F5CD;
    color: #005523; }
    .alert--success a {
      color: #005523; }
  .alert--error {
    background: #FFCCC1;
    color: #FF441A; }
    .alert--error a {
      color: #FF441A; }
  .alert__close {
    position: absolute;
    top: calc(32px - 6px);
    right: 32px;
    z-index: 2; }
    .alert__close label {
      cursor: pointer; }

#Alert_Close:checked + .alert__close {
  display: none; }
  #Alert_Close:checked + .alert__close + .alert {
    top: -30px;
    opacity: 0; }
    #Alert_Close:checked + .alert__close + .alert + .ribbon__heading {
      opacity: 1; }

.responsive-embed {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 56.25% 0 0; }
  .responsive-embed::before {
    display: block;
    content: ''; }
  .responsive-embed iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
    background: #111111; }

.mt-1x {
  margin-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .mt-1x {
      margin-top: 0.5rem; } }

.mt-2x {
  margin-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .mt-2x {
      margin-top: 1rem; } }

.mt-3x {
  margin-top: 0.85714rem; }
  @media screen and (min-width: 48em) {
    .mt-3x {
      margin-top: 1.5rem; } }

.mt-4x {
  margin-top: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .mt-4x {
      margin-top: 2rem; } }

.mt-8x {
  margin-top: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .mt-8x {
      margin-top: 4rem; } }

.mt-10x {
  margin-top: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .mt-10x {
      margin-top: 5rem; } }

.mt-0 {
  margin-top: 0; }

@media screen and (min-width: 48em) {
  .mt-sm-1x {
    margin-top: 0.5rem; }
  .mt-sm-2x {
    margin-top: 1rem; }
  .mt-sm-3x {
    margin-top: 1.5rem; }
  .mt-sm-4x {
    margin-top: 2rem; }
  .mt-sm-8x {
    margin-top: 4rem; }
  .mt-sm-10x {
    margin-top: 5rem; }
  .mt-sm-0 {
    margin-top: 0; } }

@media screen and (min-width: 64em) {
  .mt-md-1x {
    margin-top: 0.5rem; }
  .mt-md-2x {
    margin-top: 1rem; }
  .mt-md-3x {
    margin-top: 1.5rem; }
  .mt-md-4x {
    margin-top: 2rem; }
  .mt-md-8x {
    margin-top: 4rem; }
  .mt-md-10x {
    margin-top: 5rem; }
  .mt-md-0 {
    margin-top: 0; } }

.mb-1x {
  margin-bottom: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .mb-1x {
      margin-bottom: 0.5rem; } }

.mb-2x {
  margin-bottom: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .mb-2x {
      margin-bottom: 1rem; } }

.mb-3x {
  margin-bottom: 0.85714rem; }
  @media screen and (min-width: 48em) {
    .mb-3x {
      margin-bottom: 1.5rem; } }

.mb-4x {
  margin-bottom: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .mb-4x {
      margin-bottom: 2rem; } }

.mb-8x {
  margin-bottom: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .mb-8x {
      margin-bottom: 4rem; } }

.mb-10x {
  margin-bottom: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .mb-10x {
      margin-bottom: 5rem; } }

.mb-0 {
  margin-bottom: 0; }
  @media screen and (min-width: 48em) {
    .mb-0 {
      margin-bottom: 0; } }

@media screen and (min-width: 48em) {
  .mb-sm-1x {
    margin-bottom: 0.5rem; }
  .mb-sm-2x {
    margin-bottom: 1rem; }
  .mb-sm-3x {
    margin-bottom: 1.5rem; }
  .mb-sm-4x {
    margin-bottom: 2rem; }
  .mb-sm-8x {
    margin-bottom: 4rem; }
  .mb-sm-10x {
    margin-bottom: 5rem; }
  .mb-sm-0 {
    margin-bottom: 0; } }

@media screen and (min-width: 64em) {
  .mb-md-1x {
    margin-bottom: 0.5rem; }
  .mb-md-2x {
    margin-bottom: 1rem; }
  .mb-md-3x {
    margin-bottom: 1.5rem; }
  .mb-md-4x {
    margin-bottom: 2rem; }
  .mb-md-8x {
    margin-bottom: 4rem; }
  .mb-md-10x {
    margin-bottom: 5rem; }
  .mb-md-0 {
    margin-bottom: 0; } }

.pt-1x {
  padding-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .pt-1x {
      padding-top: 0.5rem; } }

.pt-2x {
  padding-top: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .pt-2x {
      padding-top: 1rem; } }

.pt-3x {
  padding-top: 0.85714rem; }
  @media screen and (min-width: 48em) {
    .pt-3x {
      padding-top: 1.5rem; } }

.pt-4x {
  padding-top: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .pt-4x {
      padding-top: 2rem; } }

.pt-8x {
  padding-top: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .pt-8x {
      padding-top: 4rem; } }

.pt-10x {
  padding-top: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .pt-10x {
      padding-top: 5rem; } }

.pt-0 {
  padding-top: 0; }

@media screen and (min-width: 48em) {
  .pt-sm-1x {
    padding-top: 0.5rem; }
  .pt-sm-2x {
    padding-top: 1rem; }
  .pt-sm-3x {
    padding-top: 1.5rem; }
  .pt-sm-4x {
    padding-top: 2rem; }
  .pt-sm-8x {
    padding-top: 4rem; }
  .pt-sm-10x {
    padding-top: 5rem; }
  .pt-sm-0 {
    padding-top: 0; } }

@media screen and (min-width: 64em) {
  .pt-md-1x {
    padding-top: 0.5rem; }
  .pt-md-2x {
    padding-top: 1rem; }
  .pt-md-3x {
    padding-top: 1.5rem; }
  .pt-md-4x {
    padding-top: 2rem; }
  .pt-md-8x {
    padding-top: 4rem; }
  .pt-md-10x {
    padding-top: 5rem; }
  .pt-md-0 {
    padding-top: 0; } }

.pb-1x {
  padding-bottom: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .pb-1x {
      padding-bottom: 0.5rem; } }

.pb-2x {
  padding-bottom: 0.57143rem; }
  @media screen and (min-width: 48em) {
    .pb-2x {
      padding-bottom: 1rem; } }

.pb-3x {
  padding-bottom: 0.85714rem; }
  @media screen and (min-width: 48em) {
    .pb-3x {
      padding-bottom: 1.5rem; } }

.pb-4x {
  padding-bottom: 1.14286rem; }
  @media screen and (min-width: 48em) {
    .pb-4x {
      padding-bottom: 2rem; } }

.pb-8x {
  padding-bottom: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .pb-8x {
      padding-bottom: 4rem; } }

.pb-10x {
  padding-bottom: 2.28571rem; }
  @media screen and (min-width: 48em) {
    .pb-10x {
      padding-bottom: 5rem; } }

.pb-0 {
  padding-bottom: 0; }

@media screen and (min-width: 48em) {
  .pb-sm-1x {
    padding-bottom: 0.5rem; }
  .pb-sm-2x {
    padding-bottom: 1rem; }
  .pb-sm-3x {
    padding-bottom: 1.5rem; }
  .pb-sm-4x {
    padding-bottom: 2rem; }
  .pb-sm-8x {
    padding-bottom: 4rem; }
  .pb-sm-10x {
    padding-bottom: 5rem; }
  .pb-sm-0 {
    padding-bottom: 0; } }

@media screen and (min-width: 64em) {
  .pb-md-1x {
    padding-bottom: 0.5rem; }
  .pb-md-2x {
    padding-bottom: 1rem; }
  .pb-md-3x {
    padding-bottom: 1.5rem; }
  .pb-md-4x {
    padding-bottom: 2rem; }
  .pb-md-8x {
    padding-bottom: 4rem; }
  .pb-md-10x {
    padding-bottom: 5rem; }
  .pb-md-0 {
    padding-bottom: 0; } }

.pr-0 {
  padding-right: 0; }

@media screen and (min-width: 48em) {
  .pr-sm-0 {
    padding-right: 0; } }

@media screen and (min-width: 64em) {
  .pr-md-0 {
    padding-right: 0; } }

@media screen and (min-width: 75em) {
  .pr-lg-0 {
    padding-right: 0; } }

.pl-0 {
  padding-left: 0; }

@media screen and (min-width: 48em) {
  .pl-sm-0 {
    padding-left: 0; } }

@media screen and (min-width: 64em) {
  .pl-md-0 {
    padding-left: 0; } }

@media screen and (max-width: 767px) {
  .full-width-content {
    margin-right: -1.14286rem;
    margin-left: -1.14286rem; } }

.bb {
  border-bottom: 1px solid #E8E8E8; }
  .bb-2 {
    border-bottom: 2px solid #E8E8E8; }

.bt-0, .bt-0:first-of-type, .bt-0:last-of-type {
  border-top: 0; }

.card__body + .card__body.bt-0 {
  border-top: 0; }

.clearfix::before, .clearfix::after {
  display: block;
  content: ' '; }

.clearfix::after {
  clear: both; }

.pull-right {
  float: right; }

.start-xs {
  justify-content: flex-start;
  text-align: left; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: right; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .start-sm {
    justify-content: flex-start;
    text-align: left; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: right; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 64em) {
  .start-md {
    justify-content: flex-start;
    text-align: left; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: right; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .start-lg {
    justify-content: flex-start;
    text-align: left; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: right; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-column {
  display: flex;
  flex-direction: column; }

.flex-basis-auto {
  flex-basis: auto; }

.mw-100 {
  max-width: 100%; }

.mh-100 {
  max-height: 100%; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700; }

.h1,
.h2,
.h3 {
  font-size: 1.42857rem;
  line-height: 1.2; }
  @media screen and (min-width: 64em) {
    .h1,
    .h2,
    .h3 {
      font-size: 1.5rem; } }

.h4 {
  font-size: 1.125rem; }

.h5 {
  font-size: 1rem; }

.h6 {
  font-size: 0.875rem; }

.text--bold {
  font-weight: 700; }

.text--italic {
  font-style: italic; }

.text--underline {
  text-decoration: underline; }

.text--uppercase {
  text-transform: uppercase; }

.text--lowercase {
  text-transform: lowercase; }

.text--capitalize {
  text-transform: capitalize; }

.text--left {
  text-align: left; }

.text--right {
  text-align: right; }

.text--center {
  text-align: center; }

.text--orange, .text--brand, .text--warning {
  color: #F77F00; }

.text--white {
  color: #FFFFFF; }

.text--grey, .text--gray {
  color: #E0E0E1; }

.text--tertiary {
  color: #B2B2B2; }

.text--success {
  color: #41D54A; }

.text--error {
  color: #FC3411; }

.bg--white {
  background-color: #FFFFFF; }

.bg--grey, .bg--gray {
  background-color: #F8F9FA; }

.bg--dark-grey, .bg--dark-gray {
  background-color: #2D2D2D; }

.bg--black {
  background-color: #111111; }

.bg--brand, .bg--orange {
  background-color: #F77F00; }

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none; } }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none; } }

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hidden-md {
    display: none; } }

@media screen and (min-width: 75em) {
  .hidden-lg {
    display: none; } }

@media screen and (min-width: 48em) {
  .visible-xs {
    display: none; } }

@media screen and (max-width: 767px) {
  .visible-sm {
    display: none; } }

@media screen and (min-width: 64em) {
  .visible-sm {
    display: none; } }

@media screen and (max-width: 1023px) {
  .visible-md {
    display: none; } }

@media screen and (min-width: 75em) {
  .visible-md {
    display: none; } }

@media screen and (max-width: 1199px) {
  .visible-lg {
    display: none; } }

.sr-only {
  position: absolute;
  left: -99999px;
  opacity: 0; }

@font-face {
  font-family: "nyc-icon";
  font-weight: normal;
  font-style: normal;
  src: url("~yc-style-guide/statics/icons/nyc-icon.eot");
  src: url("~yc-style-guide/statics/icons/nyc-icon.eot?#iefix") format("embedded-opentype"), url("~yc-style-guide/statics/icons/nyc-icon.woff") format("woff"), url("~yc-style-guide/statics/icons/nyc-icon.ttf") format("truetype"); }

/* VARIABLES */
@media screen and (min-width: 48em) and (max-width: 1023px) {
  .container {
    width: 59rem; } }

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

body {
  height: auto;
  transform: translate3d(0);
  margin: 0;
  padding: 0;
  font: 13px/1.7 "Work Sans", sans-serif;
  color: #000;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    body {
      display: inline; } }
  @media screen and (min-width: 768px) {
    body {
      overflow: hidden; } }

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  top: 25%;
  left: 0%;
  width: 100vw; }
  @media screen and (min-width: 768px) {
    .loader {
      top: 0;
      height: 100vh; } }
  .loader-container {
    display: flex;
    flex-direction: column; }
    .loader-container__logo {
      width: 80%;
      height: auto;
      margin: 0 auto; }
  .loader__text {
    font-size: 20px;
    text-align: center;
    color: #f77f00;
    font-weight: 900;
    line-height: 1.11;
    text-transform: uppercase;
    margin-top: 16px; }
    @media screen and (min-width: 768px) {
      .loader__text {
        font-size: 35 px;
        letter-spacing: 0.1px;
        font-size: 28px; } }
  @media screen and (min-width: 768px) and (min-width: 1024px) {
    .loader__text {
      font-size: 38px; } }

.dashboard {
  transform-origin: 50% 50% 0; }
  @media screen and (max-height: 650px) {
    .dashboard {
      transform-origin: 50% 0 0; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .dashboard {
      transform-origin: 50% 0; } }
  .dashboard .left-column,
  .dashboard .right-column {
    width: 100%; }
  @media (min-width: 767px) {
    .dashboard .left-column {
      width: 337px;
      margin: 15px; }
    .dashboard .right-column {
      width: 787px;
      margin: 15px; }
    .dashboard .left-column,
    .dashboard .right-column {
      height: 700px;
      overflow: hidden; } }

.header {
  background-color: #111111;
  color: #000;
  height: 50px; }
  @media (max-width: 767px) {
    .header {
      height: auto;
      text-align: center; }
      .header > div,
      .header > h3 {
        width: 100%; } }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    .header {
      height: auto; } }
  .header .nav {
    padding: 10px 10px 10px 0; }
  .header .logo {
    background-image: url("/images/ycLogo.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0px 20px 5px 0px;
    width: 50px;
    height: 50px;
    float: left; }
    @media (max-width: 767px) {
      .header .logo {
        margin: 0px auto;
        float: none; } }
    @media screen and (min-width: 1024px) {
      .header .logo {
        width: 30px;
        height: 30px; } }
  .header h3 {
    color: #fff;
    font-weight: 900;
    margin: 0;
    font-size: 20px; }
    @media (max-width: 767px) {
      .header h3 {
        text-align: center;
        line-height: 25px;
        margin: 10px 0; } }
    @media screen and (min-width: 767px) and (max-width: 1023px) {
      .header h3 {
        line-height: 50px; } }

.clock {
  font-size: 38px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.1px;
  color: #ffffff;
  position: relative; }
  @media (max-width: 767px) {
    .clock {
      color: #fff;
      float: none;
      font-size: 16px;
      line-height: 16px;
      position: relative;
      right: auto;
      font-weight: 900; } }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    .clock {
      line-height: 50px; } }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .clock {
      font-size: 34px;
      line-height: 1.31;
      position: relative; } }
  @media screen and (min-width: 1200px) {
    .clock {
      position: relative;
      left: 10px;
      width: 30%; } }

.signups .img-responsive {
  width: 90px;
  height: auto; }
  @media screen and (max-width: 767px) {
    .signups .img-responsive {
      margin: 40px auto 20px; } }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .signups .img-responsive {
      width: 90px; } }
  .signups .img-responsive-float {
    position: absolute;
    top: 135px;
    left: 0; }
    @media screen and (min-width: 1200px) {
      .signups .img-responsive-float {
        top: 135px; } }

.signups h3 {
  text-align: center !important;
  color: #fff; }
  .signups h3.total {
    font-size: 33px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.1px;
    color: #ffffff; }
    @media screen and (max-width: 767px) {
      .signups h3.total {
        margin-top: 25px; } }
    @media screen and (min-width: 375px) {
      .signups h3.total {
        font-size: 36px; } }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .signups h3.total {
        font-size: 32px; } }

.signups .number {
  display: flex;
  justify-content: flex-start; }
  .signups .number-h5 {
    font-size: 19.1px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: 0.1px;
    color: #ffffff;
    padding: 17px 15px; }
  .signups .number-display {
    display: flex;
    align-items: center; }
  .signups .number-block {
    font-size: 38px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin: 15px 0;
    padding: 0 0 0 15px; }
    .signups .number-block-year {
      padding-right: 15px;
      border-right: 5px solid #022baa; }
    .signups .number-block-month {
      padding-right: 15px;
      border-right: 5px solid #00bcb4; }
    .signups .number-block-week {
      padding-right: 15px;
      border-right: 5px solid #f77f00; }
    .signups .number-block-day {
      padding-right: 15px;
      border-right: 5px solid #ea1d76; }
    .signups .number-block__style {
      background-color: #fff; }
    .signups .number-block__total {
      display: inline-block;
      font-size: 38px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: 0.1px;
      color: #111111;
      background-color: #fff;
      width: 90%; }
    .signups .number-block__orange {
      display: inline-block;
      font-size: 41px;
      font-weight: 800;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.1px;
      color: #f77f00;
      background-color: #f77f00;
      width: 10%; }
  .signups .number hr {
    border: none;
    height: 1px;
    background-color: #ccc;
    margin: 10px 0; }

.panel {
  background: #000;
  margin: 0;
  padding: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1); }
  @media (max-width: 767px) {
    .panel {
      margin: 10px 0 0 0;
      padding: 20px 0; } }
  @media screen and (min-width: 678px) and (max-width: 1023px) {
    .panel:first-of-type {
      margin-bottom: 0px; } }
  .panel h5 {
    text-align: left;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .panel h5 {
        font-size: 22px; } }

.panel-padding {
  padding: 20px 10px 20px 20px !important; }
  @media screen and (max-width: 767px) {
    .panel-padding {
      padding: 0 !important; } }

.carousel {
  margin: 0; }
  @media screen and (max-width: 1023px) {
    .carousel {
      margin: 0 0 15px 0 !important; } }
  @media screen and (min-width: 1024px) {
    .carousel {
      padding-bottom: 0; } }

svg > g > g:last-child {
  pointer-events: none; }

.carousel-panel {
  height: 381px;
  background-color: #000; }
  @media screen and (min-width: 1024px) {
    .carousel-panel {
      margin-top: 0px; } }
  @media screen and (min-width: 1400px) {
    .carousel-panel .carousel-fitter {
      margin: 0 0px;
      width: calc(100% - 1px) !important; } }
  .carousel-panel .relativeness {
    position: relative; }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .carousel-panel .relativeness svg {
        width: 360px;
        height: 100%; } }
    .carousel-panel .relativeness svg circle {
      r: 7; }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        .carousel-panel .relativeness svg circle {
          r: 5; } }
  .carousel-panel .nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
    .carousel-panel .nav-but {
      pointer-events: all;
      position: absolute;
      top: -23px;
      right: 49px;
      background-color: transparent;
      border: none;
      font-size: 18px; }
      .carousel-panel .nav-but__play {
        opacity: 0; }
        .carousel-panel .nav-but__play:before {
          font-family: "nyc-icon";
          content: "\EA38";
          color: white; }
      .carousel-panel .nav-but__stop {
        opacity: 1; }
        .carousel-panel .nav-but__stop:before {
          font-family: "nyc-icon";
          content: "\EA36";
          color: white; }
  .carousel-panel .carousel-info {
    background-color: #ffffff;
    margin: 0 1px; }
    .carousel-panel .carousel-info .carousel-title {
      font-family: "Work Sans";
      margin: 20px;
      color: #000;
      font-size: 19.2px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0.1px; }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        .carousel-panel .carousel-info .carousel-title {
          font-size: 14px; } }
      .carousel-panel .carousel-info .carousel-title__1 {
        margin-left: 7px; }
        @supports (-moz-osx-font-smoothing: grayscale) {
          .carousel-panel .carousel-info .carousel-title__1 {
            text-align: left; } }
      .carousel-panel .carousel-info .carousel-title__2 {
        margin-left: 40px; }
        @supports (-moz-osx-font-smoothing: grayscale) {
          .carousel-panel .carousel-info .carousel-title__2 {
            position: relative;
            left: -5px; } }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .carousel-panel .carousel-info .carousel-title__2 {
            margin-left: 25px; } }
      .carousel-panel .carousel-info .carousel-title__3 {
        margin-left: 40px; }
        @supports (-moz-osx-font-smoothing: grayscale) {
          .carousel-panel .carousel-info .carousel-title__3 {
            text-align: right;
            position: relative;
            left: -3px; } }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .carousel-panel .carousel-info .carousel-title__3 {
            margin-left: 20px; } }
      .carousel-panel .carousel-info .carousel-title__4 {
        margin-left: -5px; }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .carousel-panel .carousel-info .carousel-title__4 {
            margin-left: -5px; } }
      .carousel-panel .carousel-info .carousel-title__5 {
        margin-left: 30px; }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .carousel-panel .carousel-info .carousel-title__5 {
            margin-left: 15px; } }
      .carousel-panel .carousel-info .carousel-title__6 {
        margin-left: 30px; }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          .carousel-panel .carousel-info .carousel-title__6 {
            margin-left: 0px; } }
        @media screen and (min-width: 1024px) and (max-width: 1200px) {
          .carousel-panel .carousel-info .carousel-title__6 {
            margin-left: 15px; } }
  .carousel-panel__title {
    position: relative;
    z-index: 10;
    width: 50%;
    text-align: left;
    font-size: 34px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.1px;
    color: #ffffff;
    margin-top: 30px;
    margin-left: 0px;
    margin-bottom: -20px; }
    @media screen and (min-width: 375px) {
      .carousel-panel__title {
        font-size: 36px;
        margin: 25px 0 -20px 0px; } }
    @media screen and (min-width: 768px) {
      .carousel-panel__title {
        font-size: 36px;
        margin: 0 0 -15px 20px; } }

.button:hover::before {
  color: #f77f00ed; }

.carousel {
  position: relative; }
  @media screen and (min-width: 768px) {
    .carousel {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .carousel {
      background: none;
      border: none;
      box-shadow: none;
      padding: 0; } }
  @media (max-width: 767px) {
    .carousel .panel {
      padding: 20px 0; }
      .carousel .panel:last-of-type {
        margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    .carousel .panel .gender {
      margin-top: -40px; } }
  .carousel .slick-arrow {
    background-color: #fff; }
  .carousel .slick-track,
  .carousel .slick-slider {
    height: 310px; }
  .carousel .slick-next {
    transform: rotate(270deg);
    right: 13px;
    top: -35px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .carousel .slick-next {
        right: 5px; } }
  .carousel .slick-prev {
    transform: rotate(90deg);
    left: 615px;
    top: -51px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .carousel .slick-prev {
        left: 514px; } }
    @media screen and (min-width: 1200px) {
      .carousel .slick-prev {
        left: 633px; } }
  .carousel .slick-arrow:before {
    font-family: "nyc-icon";
    content: "\EA01";
    height: 33px;
    width: 33px; }
  .carousel .slick-prev,
  .carousel .slick-next {
    width: 33px;
    height: 33px; }
  .carousel .slick-next:before,
  .carousel .slick-prev:before {
    font-size: 50px;
    position: relative;
    line-height: 1;
    opacity: 1;
    color: #000; }
  .carousel .slick-next:before {
    top: -1px;
    left: -1px; }
  .carousel .slick-prev:before {
    top: -1px;
    left: -1px; }
  .carousel .control-next.control-arrow:hover,
  .carousel .control-prev.control-arrow:hover {
    background: white; }

@media screen and (max-width: 374px) {
  .chartjs-render-monitor {
    margin-top: -60px !important; } }

@media screen and (max-width: 767px) {
  .chartjs-render-monitor {
    width: 200px !important;
    height: auto !important;
    margin: 0 auto;
    margin-top: 0px; } }

@media screen and (min-width: 768px) {
  .chartjs-render-monitor {
    margin: -20px 0 0 0;
    width: 180px !important;
    height: auto !important; } }

@media screen and (min-width: 1200px) {
  .chartjs-render-monitor {
    margin: 0;
    width: 200px !important;
    height: auto !important; } }

@media screen and (min-width: 1500px) {
  .chartjs-render-monitor {
    height: auto !important; } }

.legend {
  margin-top: -105px !important; }
  @media screen and (max-width: 374px) {
    .legend {
      margin-left: -5px; } }
  @media screen and (min-width: 375px) {
    .legend {
      margin-top: -70px;
      margin-left: 20px !important; } }
  @media screen and (max-width: 767px) {
    .legend {
      margin-top: -80px !important; } }
  @media screen and (min-width: 768px) {
    .legend {
      margin-left: -5px !important; } }
  @media screen and (min-width: 375px) {
    .legend .legend__ul {
      margin-left: -22px; } }
  @media screen and (min-width: 767px) {
    .legend .legend__ul {
      margin-left: -14px; } }
  .legend .legend__ul .legend__ul-li .legend-info {
    color: #fff;
    margin: 0;
    max-height: 20px; }
    .legend .legend__ul .legend__ul-li .legend-info::before {
      font-size: 40px;
      position: relative;
      top: 7px; }
  .legend .legend__ul .legend__ul-li .legend-19::before,
  .legend .legend__ul .legend__ul-li .legend-man::before {
    content: "■";
    color: #052fa3; }
  .legend .legend__ul .legend__ul-li .legend-20::before {
    content: "■";
    color: #11bbb2; }
  .legend .legend__ul .legend__ul-li .legend-25::before,
  .legend .legend__ul .legend__ul-li .legend-woman::before {
    content: "■";
    color: #ef0077; }
  .legend .legend__ul .legend__ul-li .legend-35::before {
    content: "■";
    color: #f77f00ed; }

.europe__map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  display: block; }
  @media screen and (max-width: 767px) {
    .europe__map {
      display: none; } }
  .europe__map-img {
    position: relative;
    width: 100%;
    height: 100%;
    right: 0;
    top: -35px; }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      .europe__map-img {
        right: -70px; } }
    @media screen and (min-width: 1200px) {
      .europe__map-img {
        right: -145px;
        width: 80%;
        height: 80%; } }

@media (min-width: 767px) {
  .europe .countries {
    min-height: 378px;
    position: relative;
    left: 1px; } }

@media (max-width: 767px) {
  .europe {
    margin-top: -40px !important;
    background: none;
    height: auto; }
    .europe .countries {
      column-count: 2;
      margin-top: 20px; } }

.europe .country-carousel {
  position: relative; }

.europe .country-count {
  display: block;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  height: 28px; }
  @media screen and (max-width: 400px) {
    .europe .country-count {
      font-size: 11px; } }
  @media (max-width: 767px) {
    .europe .country-count {
      position: static;
      margin-left: 0 !important;
      margin-top: 0 !important;
      width: auto;
      text-transform: uppercase; } }
  .europe .country-count .visible-sm-block {
    display: none; }
    @media (max-width: 767px) {
      .europe .country-count .visible-sm-block {
        display: inline-block; } }
  .europe .country-count .flag {
    background-position: center center;
    background-size: cover;
    width: 30px;
    height: 19px;
    margin: 0;
    padding: 5px;
    background-repeat: no-repeat;
    display: inline-block;
    position: relative;
    top: 5px; }
    @media (max-width: 767px) {
      .europe .country-count .flag {
        margin-right: 9px; } }
    @media screen and (max-width: 374px) {
      .europe .country-count .flag {
        width: 15px;
        height: 15px; } }
  .europe .country-count .code {
    font-weight: 800; }

.europe .country-total {
  padding-left: 10px; }
  @media screen and (min-width: 676px) {
    .europe .country-total .country-total {
      margin-left: 9px;
      display: inline-block; } }

.europe .nl .flag {
  background-image: url("/images/nl.GIF"); }

.europe .be .flag {
  background-image: url("/images/be.GIF"); }

.europe .de .flag {
  background-image: url("/images/de.GIF"); }

.europe .fr .flag {
  background-image: url("/images/fr.GIF"); }

.europe .es .flag {
  background-image: url("/images/es.GIF"); }

.europe .at .flag {
  background-image: url("/images/at.GIF"); }

.europe .uk .flag {
  background-image: url("/images/gb.GIF"); }

.europe .se .flag {
  background-image: url("/images/se.GIF"); }

.europe .ie .flag {
  background-image: url("/images/ie.GIF"); }

.europe .ch .flag {
  background-image: url("/images/ch.GIF"); }

.candidate-photos__title {
  text-align: left;
  font-size: 31px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.1px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px; }
  @media screen and (min-width: 768px) {
    .candidate-photos__title {
      margin-top: 0; } }
  @media screen and (min-width: 375px) {
    .candidate-photos__title {
      font-size: 38px; } }
  @media screen and (min-width: 1024px) {
    .candidate-photos__title {
      padding-top: 0; } }

.candidate-photos .photo-container {
  overflow: hidden; }
  .candidate-photos .photo-container .transition-box {
    transform-style: preserve-3d;
    position: relative; }
    .candidate-photos .photo-container .transition-box img {
      position: absolute; }
  .candidate-photos .photo-container img.invisible {
    opacity: 0;
    transform: rotateY(0deg);
    transition: 1s; }
  .candidate-photos .photo-container img.visible {
    opacity: 1;
    transform: rotateY(180deg);
    transition: 1s; }
  .candidate-photos .photo-container div {
    margin: 1px 10px 1px 0;
    display: inline-block; }
    @media screen and (min-width: 0px) and (max-width: 374px) {
      .candidate-photos .photo-container div {
        width: 64px;
        height: 64px; }
        .candidate-photos .photo-container div:nth-of-type(4n + 4) {
          margin-right: 0; } }
    @media screen and (min-width: 375px) and (max-width: 424px) {
      .candidate-photos .photo-container div {
        width: 60px;
        height: 60px; }
        .candidate-photos .photo-container div:nth-of-type(5n + 5) {
          margin-right: 0; } }
    @media screen and (min-width: 425px) and (max-width: 767px) {
      .candidate-photos .photo-container div {
        width: 70px;
        height: 70px; }
        .candidate-photos .photo-container div:nth-of-type(5n + 5) {
          margin-right: 0; } }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      .candidate-photos .photo-container div {
        width: 48.8px;
        height: 48.8px; }
        .candidate-photos .photo-container div:nth-of-type(10n + 10) {
          margin-right: 0; } }
    @media screen and (min-width: 1024px) and (max-width: 1199px) {
      .candidate-photos .photo-container div {
        width: 48px;
        height: 48px; }
        .candidate-photos .photo-container div:nth-of-type(10n + 10) {
          margin-right: 0 !important; } }
    @media screen and (min-width: 1200px) {
      .candidate-photos .photo-container div {
        width: 49px;
        height: 49px; }
        .candidate-photos .photo-container div:nth-of-type(12n + 12) {
          margin-right: 0 !important; } }
    .candidate-photos .photo-container div .photo {
      width: 62px;
      height: 62px; }
      @media screen and (min-width: 0px) and (max-width: 374px) {
        .candidate-photos .photo-container div .photo {
          width: 64px;
          height: 64px; } }
      @media screen and (min-width: 375px) and (max-width: 424px) {
        .candidate-photos .photo-container div .photo {
          width: 60px;
          height: 60px; } }
      @media screen and (min-width: 425px) and (max-width: 767px) {
        .candidate-photos .photo-container div .photo {
          width: 70px;
          height: 70px; } }
      @media screen and (min-width: 768px) and (max-width: 1023px) {
        .candidate-photos .photo-container div .photo {
          width: 48.8px;
          height: 48.8px; } }
      @media screen and (min-width: 1024px) and (max-width: 1199px) {
        .candidate-photos .photo-container div .photo {
          width: 48px;
          height: 48px; } }
      @media screen and (min-width: 1200px) {
        .candidate-photos .photo-container div .photo {
          width: 49px;
          height: 49px; } }

.candidates__time {
  max-width: 45px; }

.candidates__name {
  max-width: 52px; }

.candidates__from {
  max-width: 80px; }

.candidates__site {
  text-align: center;
  max-width: 35px; }

@media (min-width: 767px) {
  .candidates {
    column-count: 2;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 1;
    /* Firefox */ } }

.candidates .table tbody tr:hover td {
  background-color: transparent; }

.candidates table.table-striped {
  width: 100%; }
  .candidates table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #292929;
    color: #fff; }
    .candidates table.table-striped tbody tr:nth-of-type(odd):hover {
      background-color: rgba(41, 41, 41, 0.7); }
  .candidates table.table-striped tbody tr:nth-of-type(even) {
    background-color: #000;
    color: #fff; }
    .candidates table.table-striped tbody tr:nth-of-type(even):hover {
      background-color: #111; }
  .candidates table.table-striped td {
    padding: 6px;
    font-size: 12px;
    color: #ffffff;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .candidates table.table-striped td,
  .candidates table.table-striped tr,
  .candidates table.table-striped tbody {
    border: none; }
