@import "~yc-style-guide/scss/style";
@include font-face("nyc-icon", "~yc-style-guide/statics/icons/nyc-icon");

/* VARIABLES */
$sliderHeight: 350px;
$whiteBoxVMargin: 20px;
$whiteBoxPadding: 20px;
$numberBlockVMargin: 60px;
$numberBlockHeight: 60px;
$photoSize: 48px;
$smallScreen: 767px;
$headerHeight: 50px;
$fontColor: #000;
$headerText__color: #fff;
$color-white: #fff;
$color-black: #000;

// styleguide overrides

.container {
  @media screen and (min-width: 48em) and (max-width: 1023px) {
    width: 59rem;
  }
}

.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// IE Specific styling for IE 10+

body {
  height: auto;
  transform: translate3d(0);
  margin: 0;
  padding: 0;
  font: 13px/1.7 "Work Sans", sans-serif;
  color: $fontColor;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline;
  }
  @media screen and (min-width: 768px) {
    overflow: hidden;
  }
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  top: 25%;
  left: 0%;
  width: 100vw;
  @media screen and (min-width: 768px) {
    top: 0;
    height: 100vh;
  }
  &-container {
    display: flex;
    flex-direction: column;
    &__logo {
      width: 80%;
      height: auto;
      margin: 0 auto;
    }
  }
  &__text {
    font-size: 20px;
    text-align: center;
    color: #f77f00;
    font-weight: 900;
    line-height: 1.11;
    text-transform: uppercase;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      font-size: 35 px;
      letter-spacing: 0.1px;
      font-size: 28px;

      @media screen and (min-width: 1024px) {
        font-size: 38px;
      }
    }
  }
}

.dashboard {
  @media screen and (max-height: 650px) {
    transform-origin: 50% 0 0;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    transform-origin: 50% 0;
  }
  transform-origin: 50% 50% 0;

  .left-column,
  .right-column {
    width: 100%;
  }
  @media (min-width: $smallScreen) {
    .left-column {
      width: 337px;
      margin: 15px;
    }
    .right-column {
      width: 787px;
      margin: 15px;
    }
    .left-column,
    .right-column {
      height: 700px;
      overflow: hidden;
    }
  }
}

.header {
  background-color: #111111;
  color: $fontColor;
  height: $headerHeight;
  @media (max-width: $smallScreen) {
    height: auto;
    text-align: center;
    > div,
    > h3 {
      width: 100%;
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    height: auto;
  }

  .nav {
    padding: 10px 10px 10px 0;
  }

  .logo {
    background-image: url("/images/ycLogo.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0px 20px 5px 0px;
    width: ($headerHeight * 1.2) - 10;
    height: ($headerHeight * 1.2) - 10;
    float: left;
    @media (max-width: $smallScreen) {
      margin: 0px auto;
      float: none;
    }
    @media screen and (min-width: 1024px) {
      width: $headerHeight - 20;
      height: $headerHeight - 20;
    }
  }

  h3 {
    color: $headerText__color;
    font-weight: 900;
    margin: 0;
    font-size: 20px;
    @media (max-width: $smallScreen) {
      text-align: center;
      line-height: $headerHeight / 2;
      margin: 10px 0;
    }
    @media screen and (min-width: 767px) and (max-width: 1023px) {
      line-height: $headerHeight;
    }
  }
}

.clock {
  font-size: 38px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: 0.1px;
  color: #ffffff;
  position: relative;
  @media (max-width: $smallScreen) {
    color: $color-white;
    float: none;
    font-size: 16px;
    line-height: 16px;
    position: relative;
    right: auto;
    font-weight: 900;
  }

  @media screen and (min-width: 767px) and (max-width: 1023px) {
    line-height: $headerHeight;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    font-size: 34px;
    line-height: 1.31;
    position: relative;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    left: 10px;
    width: 30%;
  }
}

.signups {
  .img-responsive {
    @media screen and (max-width: 767px) {
      margin: 40px auto 20px;
    }

    width: 90px;
    height: auto;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      width: 90px;
    }

    &-float {
      position: absolute;
      top: 135px;
      left: 0;
      @media screen and (min-width: 1200px) {
        top: 135px;
      }
    }
  }
  h3 {
    text-align: center !important;
    color: $color-white;

    &.total {
      font-size: 33px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.11;
      letter-spacing: 0.1px;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        margin-top: 25px;
      }

      @media screen and (min-width: 375px) {
        font-size: 36px;
      }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 32px;
      }
    }
  }
  .number {
    display: flex;
    justify-content: flex-start;
    &-h5 {
      font-size: 19.1px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: 0.1px;
      color: #ffffff;
      padding: 17px 15px;
    }
    &-display {
      display: flex;
      align-items: center;
    }
    &-block {
      font-size: 38px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.1px;
      color: #ffffff;
      margin: 15px 0;
      padding: 0 0 0 15px;
      &-year {
        padding-right: 15px;
        border-right: 5px solid #022baa;
      }
      &-month {
        padding-right: 15px;
        border-right: 5px solid #00bcb4;
      }
      &-week {
        padding-right: 15px;
        border-right: 5px solid #f77f00;
      }
      &-day {
        padding-right: 15px;
        border-right: 5px solid #ea1d76;
      }
      &__style {
        background-color: #fff;
      }
      &__total {
        display: inline-block;
        font-size: 38px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.11;
        letter-spacing: 0.1px;
        color: #111111;
        background-color: #fff;
        width: 90%;
      }
      &__orange {
        display: inline-block;
        font-size: 41px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 0.1px;
        color: #f77f00;
        background-color: #f77f00;
        width: 10%;
      }
    }
    hr {
      border: none;
      height: 1px;
      background-color: #ccc;
      margin: 10px 0;
    }
  }
}

.panel {
  background: #000;
  margin: 0;
  @media (max-width: 767px) {
    margin: 10px 0 0 0;
    padding: $whiteBoxPadding 0;
  }
  padding: $whiteBoxPadding;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 678px) and (max-width: 1023px) {
    &:first-of-type {
      margin-bottom: 0px;
    }
  }

  h5 {
    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
    text-align: left;
    color: $color-white;
  }
}
.panel-padding {
  @media screen and (max-width: 767px) {
    padding: 0 !important;
  }
  padding: 20px 10px 20px 20px !important;
}

.carousel {
  margin: 0;
  @media screen and (max-width: 1023px) {
    margin: 0 0 15px 0 !important;
  }
  @media screen and (min-width: 1024px) {
    padding-bottom: 0;
  }
}
